import { useState, useEffect } from "react";
import { Button, Checkbox, FormControlLabel } from "@mui/material";
import "./billedConfirmation.scss";

export default function BilledConfirmation({
  profile,
  revenueToggle,
  markBilled,
}) {
  const { candidate, revenue } = profile;

  const [check, setCheck] = useState(false);
  const handleChange = (event) => {
    setCheck(event.target.checked);
  };

  useEffect(() => {}, []);

  return (
    <div className="main-billed-container">
      <h3>Congratulations on Closing Profile of {candidate[0]}</h3>
      <div className="billed-rows" style={{ marginBottom: "1.5rem" }}>
        <div>Candidate was Billed for {revenue.billableCTC}</div>
        <Button
          variant="contained"
          color="secondary"
          size="small"
          onClick={revenueToggle}
        >
          Recheck Revenue
        </Button>
      </div>
      <div className="billed-rows">
        <FormControlLabel
          control={<Checkbox checked={check} onChange={handleChange} />}
          label="I have verified Revenue Details"
        />
        <Button
          variant="contained"
          color="success"
          size="small"
          disabled={!check}
          onClick={markBilled}
        >
          Confirm Bill
        </Button>
      </div>
    </div>
  );
}
