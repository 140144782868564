import "./rightdrawer.scss";
import Drawer from "@mui/material/Drawer";
import { getLandingPath } from "../../helper/reuse";
import { company, jobdetails, org, role, user } from "../../route/paths";
import DefineCompany from "../../landing/operation/company/defineCompany";
import DefineRole from "../../landing/admin/role/defineRole";
import DefineUsers from "../../landing/operation/users/defineCompany";
import DefineRevenue from "../../landing/admin/extraction/defineRevenue";

export default function RightDrawer({ open, toggle, rows, eIndex, setRows }) {
  const path = getLandingPath();

  return (
    <Drawer anchor="right" open={open} onClose={toggle}>
      <div className="main-drawer-container">
        {path === company && (
          <DefineCompany eIndex={eIndex} toggle={toggle} rows={rows} />
        )}
        {(path === role || path === org) && (
          <DefineRole eIndex={eIndex} toggle={toggle} rows={rows} />
        )}
        {path === user && (
          <DefineUsers
            toggle={toggle}
            userList={rows}
            setUserList={setRows}
            updateUser={eIndex}
          />
        )}
        {path === jobdetails && (
          <DefineRevenue
            toggle={toggle}
            eIndex={eIndex}
            rows={rows}
            setRows={setRows}
          />
        )}
      </div>
    </Drawer>
  );
}
