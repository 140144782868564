import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";

export default function DropDown({
  label,
  options,
  value,
  setValue,
  name,
  disabled,
}) {
  return (
    <FormControl fullWidth size="small">
      <InputLabel>{label}</InputLabel>
      <Select
        value={value}
        label={label}
        name={name}
        disabled={disabled}
        onChange={setValue}
      >
        {options.map((r, i) => (
          <MenuItem value={r} key={i}>
            {r}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
