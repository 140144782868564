import { useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { TextField } from "@mui/material";

export default function Search({ label, list, setList, reset, field }) {
  const [search, setSearch] = useState("");

  useEffect(() => {
    const k = list.map((item) => {
      item.hide = false;
      return item;
    });
    setList([...k]);
    setSearch("");
  }, [reset]);

  const searchUpdated = (e) => {
    const val = e.target.value;
    setSearch(val);
    const k = list.map((item) => {
      if (field.includes(".")) {
        const fields = field.split(".");
        item.hide = !item[fields[0]][fields[1]]
          .toLowerCase()
          .includes(val.toLowerCase());
      } else item.hide = !item[field].toLowerCase().includes(val.toLowerCase());
      return item;
    });
    setList([...k]);
  };

  return (
    <TextField
      style={{ width: "25%" }}
      size="small"
      label={label}
      value={search}
      onChange={searchUpdated}
      InputProps={{
        endAdornment: <SearchIcon />,
      }}
    />
  );
}
