import React, { useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { getCall, postCall } from "../../services/API";
import { userRoles } from "../../helper/constants";

export default function AutoCompleteDropDown({
  label,
  value,
  setValue,
  disableDrop,
}) {
  const [data, setData] = useState(null);
  useEffect(() => {
    if (label.includes("Available")) {
      // used in HR Ops to get list of avaiable resources
      const request = {
        type: label.includes("Admins") ? userRoles.admin : userRoles.agent,
        available: true,
      };
      postCall("users/filter", request).then((res) => {
        console.log("users", res.data);
        setData(res.data);
      });
    } else {
      // for admin to get list of all orgs
      getCall("company").then((res) => {
        console.log("companys", res);
        setData(res.data);
      });
    }
  }, []);

  return (
    data && (
      <Autocomplete
        options={data}
        disabled={disableDrop}
        defaultValue={value}
        size="small"
        isOptionEqualToValue={(option, value) => option._id === value._id}
        onChange={(e, val) => setValue(val)}
        disableClearable
        getOptionLabel={(option) => option.name || option.orgName}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              checked={selected}
            />
            <span>{option.name || option.orgName}</span>
            {option.email && (
              <span style={{ fontSize: "small", marginLeft: "10px" }}>
                ({option.email})
              </span>
            )}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            fullWidth
            placeholder="Type to Search"
          />
        )}
      />
    )
  );
}
