import { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import "./adminagentRole.scss";
import DataTable from "../../../../components/data-table/table";
import {
  agentRoleColumnNames,
  agentRoleRowFields,
} from "../../../../components/data-table/tableData";
import { getCall } from "../../../../services/API";
import Search from "../../../../components/Search";
import { getRequiredPaths } from "../../../../helper/reuse";
import NavHeader from "../../../../components/nav-header/NavigationHeader";

export default function AdminAgentJobRole() {
  const [type, agentId, cmpId] = getRequiredPaths(-3);
  const [data, setData] = useState([]);

  const sessionDetails = JSON.parse(localStorage.getItem("nt-session"));
  useEffect(() => {
    getCall(
      "jobrole/agent/" +
        agentId +
        "/" +
        cmpId +
        "/" +
        sessionDetails._id +
        "/" +
        type
    ).then((res) => {
      console.log("data ", res.data);
      setData(res.data);
    });
  }, []);

  return (
    <div className="admin-agent-container">
      <NavHeader show={1} />
      <Paper className="admin-agent-header" elevation={0}>
        <Search
          label="Search for Job Role"
          list={data}
          setList={setData}
          reset={null}
          field={agentRoleRowFields[0]}
        />
        <div></div>
      </Paper>
      <DataTable
        columns={agentRoleColumnNames}
        rows={data}
        rowKeys={agentRoleRowFields}
      />
    </div>
  );
}
