import { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import "./role.scss";
import DataTable from "../../../components/data-table/table";
import {
  roleColumnNames,
  roleRowFields,
} from "../../../components/data-table/tableData";
import { getDualPaths } from "../../../helper/reuse";
import { getCall } from "../../../services/API";
import Search from "../../../components/Search";
import RightDrawer from "../../../components/create-drawer/rightDrawer";
import NavHeader from "../../../components/nav-header/NavigationHeader";

export default function Role() {
  const sessionDetails = JSON.parse(localStorage.getItem("nt-session"));
  const [data, setData] = useState([]);
  const [, orgID] = getDualPaths();
  const [state, setState] = useState(false);
  const [editIndex, setEditIndex] = useState(-1);

  const getJobRole = () => {
    getCall("jobrole/list/" + orgID + "/" + sessionDetails._id).then((res) => {
      console.log("company ", res.data);
      setData(res.data);
    });
  };

  const toggleDrawer = (param) => {
    if (param === "success") {
      getJobRole();
    }
    setState(!state);
  };

  useEffect(() => {
    getJobRole();
  }, []);

  return (
    <div className="jobrole-main-container">
      <NavHeader show={0}/>
      <Paper className="jobrole-header-section" elevation={0}>
        <Search
          label="Search for Job Role"
          list={data}
          setList={setData}
          reset={state}
          field={roleRowFields[1]}
        />
        <div></div>
      </Paper>
      <DataTable
        columns={roleColumnNames}
        rows={data}
        rowKeys={roleRowFields}
        addToggle={toggleDrawer}
        eIndexSet={setEditIndex}
      />
      {state && (
        <RightDrawer
          open={state}
          toggle={toggleDrawer}
          rows={data}
          eIndex={editIndex}
        />
      )}
    </div>
  );
}
