import { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import "./adminagentorg.scss";
import DataTable from "../../../../components/data-table/table";
import {
  agentOrgColumnNames,
  agentOrgRowFields,
} from "../../../../components/data-table/tableData";
import { getCall } from "../../../../services/API";
import Search from "../../../../components/Search";
import { getDualPaths } from "../../../../helper/reuse";
import NavHeader from "../../../../components/nav-header/NavigationHeader";

export default function AdminAgentOrg() {
  const sessionDetails = JSON.parse(localStorage.getItem("nt-session"));
  const [data, setData] = useState([]);
  const [type, agentId] = getDualPaths();

  useEffect(() => {
    getCall(
      "company/" + agentId + "/" + sessionDetails._id + "/" + type + "/map"
    ).then((res) => {
      console.log("companyDetails ", res.data);
      setData(res.data);
    });
  }, []);

  return (
    <div className="org-main-container">
      <NavHeader show={0} />
      <Paper className="org-header-section" elevation={0}>
        <Search
          label="Search for Organisation"
          list={data}
          setList={setData}
          reset={null}
          field={agentOrgRowFields[0]}
        />
        <div></div>
      </Paper>
      <DataTable
        columns={agentOrgColumnNames}
        rows={data}
        rowKeys={agentOrgRowFields}
      />
    </div>
  );
}
