import { useState, useEffect } from "react";
import { Button, Modal, TextField } from "@mui/material";
import "./profiledetails.scss";
import {
  deleteCall,
  getCall,
  patchCall,
  postCall,
} from "../../../../services/API";
import { Link } from "react-router-dom";

export default function ProfileDetails({ open, handleClose, rows, setRows }) {
  const { candidate, _id, profileLink, cvLink, status } = rows[open];
  const [notes, setNotes] = useState("");
  const [receivedData, setReceivedData] = useState(null);
  const [deleteStatus, setDeleteStatus] = useState(true);
  const isDeletable = !(status?.stage > 2);

  useEffect(() => {
    getCall("notes/" + _id)
      .then((res) => {
        console.log("get notes", res);
        setNotes(res.data.agentNotes);
        setReceivedData(res.data);
      })
      .catch((e) => {
        console.log("not found", e);
      });
  }, []);

  const saveNotes = () => {
    if (receivedData) {
      const request = {
        agentNotes: notes,
        adminNotes: receivedData?.adminNotes,
      };
      patchCall("notes/" + receivedData._id, request).then((res) => {
        console.log("updated", res);
        handleClose();
      });
    } else {
      const request = {
        profileId: _id,
        agentNotes: notes,
        adminNotes: "",
      };
      postCall("notes", request).then((res) => {
        console.log("saved", res);
        handleClose();
      });
    }
  };

  const removeRow = () => {
    deleteCall("data/" + _id).then((res) => {
      console.log("delete", res);
      rows.splice(open, 1);
      setRows([...rows]);
      handleClose();
    });
  };

  return (
    <Modal open={open !== -1} onClose={handleClose}>
      <div className="profile-modal-container">
        <div className="profile-content-container">
          <div variant="h5" className="profile-header">
            {candidate[0]}
            {profileLink && (
              <a
                href={profileLink}
                target="_blank"
                rel="noreferrer"
                className="profile-link"
              >
                Visit Profile
              </a>
            )}
          </div>
          <div className="notes-container">
            <TextField
              label="Your Notes"
              fullWidth
              multiline
              rows={7}
              placeholder="Start Typing..."
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              size="small"
            />
            <TextField
              label="Admin Notes"
              fullWidth
              multiline
              placeholder="No Content Yet"
              rows={7}
              value={receivedData?.adminNotes}
              InputLabelProps={{
                shrink: true,
              }}
              disabled
              size="small"
            />
          </div>
          <div className="delete-profile">
            <div className="message-info">
              {1000 - notes.length} characters left of 1000
            </div>
            <Button
              className="sub"
              variant="contained"
              color="primary"
              size="small"
              onClick={saveNotes}
            >
              Save Notes
            </Button>
          </div>
          <div className="profile-interview">
            <span className="header">Education: </span>
            <span>{candidate[9]}</span>
          </div>
          <div className="profile-interview">
            <span className="header">Preferred Location: </span>
            <span>{candidate[10]}</span>
          </div>
          {cvLink && (
            <div className="profile-interview">
              <span className="header">Resume Uploaded: </span>
              <Link to={cvLink} target="_blank">
                View CV
              </Link>
            </div>
          )}
          {deleteStatus ? (
            <div className="delete-profile">
              <div className="error-info">
                Delete Profile?
                {isDeletable
                  ? " This cannot be restored"
                  : " Disbaled as Profile is Progressed"}
              </div>
              <Button
                className="sub"
                variant="contained"
                color="error"
                size="small"
                disabled={!isDeletable}
                onClick={() => setDeleteStatus(false)}
              >
                Delete
              </Button>
            </div>
          ) : (
            <div className="delete-profile">
              <div className="error-info">Are you sure want to delete?</div>
              <div>
                <Button
                  className="sub"
                  variant="outlined"
                  size="small"
                  onClick={() => setDeleteStatus(true)}
                >
                  No, Cancel
                </Button>
                <Button
                  className="sub"
                  variant="contained"
                  color="error"
                  size="small"
                  onClick={removeRow}
                >
                  Yes, Delete
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}
