import { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import DataTable from "../../../components/data-table/table";
import {
  revenueColumnNames,
  revenueRowFields,
} from "../../../components/data-table/tableData";
import { getCall } from "../../../services/API";
import Search from "../../../components/Search";

export default function AdminRevenue() {
  const [data, setData] = useState([]);

  const sessionDetails = JSON.parse(localStorage.getItem("nt-session"));
  useEffect(() => {
    getCall("data/revenue/admin/" + sessionDetails._id).then((res) => {
      console.log("data ", res.data);
      setData(res.data);
    });
  }, []);

  return (
    <div className="company-main-container">
      <Paper className="company-header-section" elevation={0}>
        <Search
          label="Search for Job Role"
          list={data}
          setList={setData}
          reset={null}
          field={revenueRowFields[2]}
        />
        <div></div>
      </Paper>
      <DataTable
        columns={revenueColumnNames}
        rows={data}
        rowKeys={revenueRowFields}
      />
    </div>
  );
}
