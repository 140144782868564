import { useState, useEffect } from "react";
import { Button, TextField } from "@mui/material";
import { columns } from "../../agent/extraction/constants";
import { getCall, patchCall, postCall } from "../../../services/API";
import { Link } from "react-router-dom";

export default function ProfileMoreDetails({ profileData }) {
  const { candidate, _id, cvLink } = profileData;
  const [notes, setNotes] = useState("");
  const [receivedData, setReceivedData] = useState(null);
  const excludedIndex = [0, 3, 6, 7];

  useEffect(() => {
    getCall("notes/" + _id)
      .then((res) => {
        console.log("get notes", res);
        setNotes(res.data.adminNotes);
        setReceivedData(res.data);
      })
      .catch((e) => {
        console.log("not found", e);
      });
  }, []);

  const saveNotes = () => {
    if (receivedData) {
      const request = {
        agentNotes: receivedData?.agentNotes,
        adminNotes: notes,
      };
      patchCall("notes/" + receivedData._id, request).then((res) => {
        console.log("updated", res);
      });
    } else {
      const request = {
        profileId: _id,
        agentNotes: "",
        adminNotes: notes,
      };
      postCall("notes", request).then((res) => {
        console.log("saved", res);
      });
    }
  };

  return (
    <div className="admin-extract-container">
      <ul>
        {columns.slice(2, 11).map(
          (h, p) =>
            !excludedIndex.includes(p) && (
              <li>
                {h} : {candidate[p]}
              </li>
            )
        )}
        <li>
          CV Attached :
          <Link to={cvLink} target="_blank">
            View CV
          </Link>
        </li>
      </ul>
      <div className="notes-admin-container">
        <TextField
          label="Your Notes"
          fullWidth
          multiline
          rows={7}
          placeholder="Start Typing..."
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          size="small"
        />
        <TextField
          label="Agent Notes"
          fullWidth
          multiline
          placeholder="No Content Yet"
          rows={7}
          value={receivedData?.agentNotes}
          InputLabelProps={{
            shrink: true,
          }}
          disabled
          size="small"
        />
      </div>
      <div className="notes-list-profile">
        <div className="limit-info">
          {1000 - notes.length} characters left of 1000
        </div>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={saveNotes}
        >
          Save Notes
        </Button>
      </div>
    </div>
  );
}
