// owner
export const companyColumnNames = [
  "Organisation Name",
  "Date",
  "Client Owner",
  "Status",
  "Type of Client",
  "Tagged Organization",
];

export const companyRowFields = [
  "orgName",
  "createdAt",
  "clientOwner",
  "status",
  "clientType",
  "taggedOrg",
];

//admin
export const orgColumnNames = [
  "Organisation Name",
  "Client Owner",
  "Total Roles created",
  "Open Positions",
  "Positive Profiles",
  "No of Joiners",
  "Billed",
];

export const orgRowFields = [
  "orgName",
  "clientOwner",
  "rolesCount",
  "positionCount",
  "positiveCount",
  "joinedCount",
  "billed",
];

export const roleColumnNames = [
  "Role ID",
  "Role Name",
  "POC Name",
  "No Of Positions",
  "No Of Agents",
  "Job Description",
  "Status",
];

export const roleRowFields = [
  "_id",
  "roleName",
  "poc.name",
  "positions",
  "agentCount",
  "description",
  "status",
];

export const agentColumnNames = [
  "Associate",
  "No of org assigned",
  "No of roles assigned",
  "No of extracted profiles",
  "No of interested candidates",
  "No of interview shortlist",
  "Billed",
];

export const agentRowFields = [
  "name",
  "orgsCount",
  "rolesCount",
  "profilesCount",
  "positiveCount",
  "joinedCount",
  "billed",
];

export const extractColumnNames = [
  "Date",
  "Recruiter",
  "Candidate Name",
  "Current Role",
  "Current Organization",
  "Experience",
  "Profile Status",
];

export const extractRowData = [
  "joiningDate",
  "agent.name",
  "candidate.0",
  "candidate.6",
  "candidate.7",
  "candidate.3",
  "status.drop",
];

export const extractAgentJobColumnNames = [
  "Date",
  "Candidate Name",
  "Current Role",
  "Current Organization",
  "Experience",
  "Profile Status",
];

export const extractAgentJobRowData = [
  "joiningDate",
  "candidate.0",
  "candidate.6",
  "candidate.7",
  "candidate.3",
  "status.option",
];

export const revenueColumnNames = [
  "Joining Date",
  "Organisation",
  "Job Role",
  "Requirement Owner",
  "Candidate Name",
  "Your Share",
  "Recruiter Share",
  "Total Revenue",
];

export const revenueRowFields = [
  "joiningDate",
  "company.orgName",
  "job.roleName",
  "owner.name",
  "candidate.0",
  "adminShareValue",
  "recruiterShare",
  "total",
];

//agents

export const agentOrgColumnNames = [
  "Organisation",
  "Org Status",
  "No of Open Position",
  "No of ext profiles",
  "No of int candidates",
  "No of interviews",
  "No of Joiners",
  "Billed",
];

export const agentOrgRowFields = [
  "orgName",
  "status",
  "positionCount",
  "extProfiles",
  "intProfiles",
  "shortProfiles",
  "joinedCount",
  "billed",
];

export const earningColumnNames = [
  "Joining Date",
  "Organisation",
  "Job Role",
  "Requirement owner",
  "Candidate Name",
  "Your Share",
  "Owner Share",
  "Total Revenue",
];

export const earningRowFields = [
  "joiningDate",
  "company.orgName",
  "job.roleName",
  "owner.name",
  "candidate.0",
  "yourShare",
  "ownerShare",
  "total",
];

export const agentRoleColumnNames = [
  "Role Name",
  "POC Name",
  "Role Status",
  "No of Open Position",
  "No of ext profiles",
  "No of int candidates",
  "No of interviews",
  "No of Joiners",
  "Billed",
];

export const agentRoleRowFields = [
  "roleName",
  "poc.name",
  "status",
  "positions",
  "extProfiles",
  "intProfiles",
  "shortProfiles",
  "joinedCount",
  "billed",
];
