import { Button } from "@mui/material";

export default function ContainedButton( props ) {
  return (
    <Button
      variant="contained"
      {...props}
      sx={{ textTransform: "none", backgroundColor: "#43c1b8 !important", padding: "0 2rem" }}
    />
  );
}
