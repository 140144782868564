import { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { patchCall } from "../../services/API";

export default function CVUpload({ jobId, closeModal }) {
  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  const fileUpload = async () => {
    const formData = new FormData();
    formData.append("file", file);
    const response = await patchCall("data/uploadCV/" + jobId, formData, true);
    closeModal(response?.data?.cvLink);
  };

  useEffect(() => {}, []);

  return (
    <div className="main-billed-container">
      <div style={{ display: "flex", alignItems: "center" }}>
        <h3>Upload Candidate CV</h3>
        <div>(Use only PDF or Doc file)</div>
      </div>
      <input type="file" accept=".pdf,.doc,.docx" onChange={handleFileChange} />
      <Button
        variant="contained"
        color="success"
        size="small"
        disabled={!file}
        onClick={fileUpload}
        style={{ marginTop: "1rem" }}
      >
        Upload
      </Button>
    </div>
  );
}
