import React, { useState } from "react";
import "./login.scss";
import { useNavigate } from "react-router-dom";
import logosvg from "../../assets/logo.svg";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { postCall } from "../../services/API";
import {
  admin,
  agent,
  companies,
  company,
  operation,
  org,
  owner,
  userView,
} from "../../route/paths";
import { userRoles } from "../../helper/constants";
import DropDown from "../../components/dropdown/Select";

export default function Login() {
  const roles = Object.values(userRoles);
  const navigate = useNavigate();

  const [errorMessages, setErrorMessages] = useState({
    show: false,
    flag: false,
  });
  const [email, setEmail] = useState("abc@gmail.com");
  const [password, setPassword] = useState("1234");
  const [userRole, setuserRole] = useState("");

  const handleSubmit = () => {
    setErrorMessages({ show: true, flag: true });
    const request = {
      email,
      password,
    };
    postCall("users/signin", request)
      .then((res) => {
        const resp = res.data;
        if (resp.role === userRoles.admin && userRole === userRoles.agent) {
          resp.role = userRoles.agent;
          resp.isAdmin = "true";
        }
        localStorage.setItem("nt-session", JSON.stringify(resp));

        if (resp.role === userRoles.admin) {
          navigate(admin + "/" + org);
        }
        if (resp.role === userRoles.agent) {
          navigate(agent + "/" + companies);
        }
        if (resp.role === userRoles.operations) {
          navigate(operation + "/" + company);
        }
        if (resp.role === userRoles.owner) {
          navigate(owner + "/" + userView);
        }
      })
      .catch((err) => {
        setErrorMessages({ show: true, flag: false });
        console.log("wefew", err);
      });
  };

  const renderErrorMessage = () =>
    errorMessages.show &&
    (!errorMessages.flag ? (
      <div className="first-error">Invalid username or password</div>
    ) : (
      <div className="first-success">Signing In ...</div>
    ));
  return (
    <div className="login-main-container">
      <div className="bg-container">
        <div className="left-section">
          <Paper className="left-form">
            <div className="title">Sign in to Account</div>
            <TextField
              size="small"
              label="Email"
              type="email"
              value={email}
              fullWidth
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              size="small"
              label="Password"
              type="password"
              fullWidth
              onChange={(e) => setPassword(e.target.value)}
            />
            <DropDown
              label="Role"
              options={roles}
              setValue={(e) => setuserRole(e.target.value)}
            />
            <div className="password-cont">
              <div className="remember-check">
                <input type="checkbox" />
                Remember Me
              </div>
              <div>Forgot Password?</div>
            </div>
            {renderErrorMessage()}
            <Button
              variant="contained"
              className="login-button"
              onClick={handleSubmit}
            >
              Login
            </Button>
          </Paper>
        </div>
        <div className="right-section">
          <div className="right-container">
            <img
              src={logosvg}
              alt="recruiter-logo"
              className="recruiter-logo"
            />
            <div className="title-logo">Hello Recruiter</div>
            <div className="title-body">
              <div>Welcome to new era of hiring!!!</div>
              <div>Click here to Signup</div>
            </div>
            <Button
              variant="outlined"
              className="sign-up"
              onClick={() => navigate("/register")}
            >
              Sign Up
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
