import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import Avatar from "@mui/material/Avatar";
import ProfileOptions from "./avatarOptions";
import leftLogo from "../../../assets/header/company.svg";
import "./header.scss";
import ActionNotification from "./notification/action";
import { userRoles } from "../../../helper/constants";

export default function Header() {
  const sessionDetails = JSON.parse(localStorage.getItem("nt-session"));
  return (
    <Toolbar className="main-header-container">
      {/* <Avatar alt="Soma" src={leftLogo} sx={{ width: 38, height: 38 }} /> */}
      <Avatar sx={{ width: 38, height: 38 }}>L</Avatar>
      <Typography variant="h5" noWrap component="div">
        Sourcer 2.0
      </Typography>
      <div>
        {sessionDetails.role === userRoles.admin && <ActionNotification />}
        <ProfileOptions />
      </div>
    </Toolbar>
  );
}
