import DataTable from "../../../../components/data-table/table";
import {
  agentColumnNames,
  agentRowFields,
} from "../../../../components/data-table/tableData";
import DownloadIcon from "@mui/icons-material/Download";
import { IconButton, Paper } from "@mui/material";
import Search from "../../../../components/Search";

export default function AgentContainer({ data, your, setData }) {
  return (
    <div className="agents-list-container">
      <Paper className="agents-list-header" elevation={0}>
        <Search
          label="Search Associates"
          list={data}
          setList={setData}
          reset={null}
          field={agentRowFields[0]}
        />
        <div className="admin-list">
          {your ? "Your Associates" : "Other Associates"}
        </div>
        <div>
          <IconButton>
            <DownloadIcon />
          </IconButton>
        </div>
      </Paper>
      <DataTable
        columns={agentColumnNames}
        rows={data}
        rowKeys={agentRowFields}
      />
    </div>
  );
}
