import { useState, useEffect } from "react";
import TableChartIcon from "@mui/icons-material/TableChart";
import BarChartIcon from "@mui/icons-material/BarChart";
import {
  MenuItem,
  Select,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";

export default function ChartMeta({ range, handleRangeSet, isAdmin }) {
  const [type, setType] = useState("chart");
  const handleTypeChange = (e, typeVal) => {
    if (typeVal) {
      setType(typeVal);
    }
  };

  return (
    <div className="chart-options-container">
      {/* <ToggleButtonGroup
        value={type}
        onChange={handleTypeChange}
        size="small"
        exclusive
      >
        <ToggleButton value="chart">
          <BarChartIcon fontSize="1rem" />
        </ToggleButton>
        <ToggleButton value="table">
          <TableChartIcon fontSize="1rem" />
        </ToggleButton>
      </ToggleButtonGroup> */}
      <div></div>
      <div className="chart-title">
        {isAdmin ? "Admin Vs Revenue" : "Organisation Vs Revenue"}
      </div>
      <Select value={range} onChange={handleRangeSet} size="small">
        <MenuItem value="Today">Today</MenuItem>
        <MenuItem value="WTD">WTD</MenuItem>
        <MenuItem value="MTD">MTD</MenuItem>
        <MenuItem value="All">All</MenuItem>
      </Select>
    </div>
  );
}
