export const setPath = (index, pathName) => {
  const storedPath = JSON.parse(sessionStorage.getItem("navigationPath")) || [];
  const item = {
    navPath: window.location.pathname,
    pathName,
  };
  storedPath[index] = item;
  sessionStorage.setItem("navigationPath", JSON.stringify(storedPath));
};

export const setDescription = (description) => {
  sessionStorage.setItem("jobDescription", JSON.stringify(description));
};
