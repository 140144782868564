import { useState, useEffect } from "react";
import { postCall } from "../../../services/API";
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { dateRanges, indianRupees } from "../../../helper/reuse";
import ChartMeta from "./dataOptions";

export default function BarRevenueChart({ type }) {
  const isAdmin = type === "admin";
  const sessionDetails = JSON.parse(localStorage.getItem("nt-session"));
  const [revenueData, setRevenueData] = useState([]);

  const [range, setRange] = useState("Today");
  const handleRangeSet = (e) => {
    setRange(e.target.value);
  };

  useEffect(() => {
    const request = {
      id: sessionDetails._id,
      from: dateRanges(range),
      to: new Date(),
    };
    postCall(
      "analytics/" + (isAdmin ? "adminVsRevenue" : "orgVsRevenue"),
      request
    ).then((res) => {
      console.log("response ", res.data);
      setRevenueData(res.data);
    });
  }, [range]);

  const renderCustomizedLabel = (ref) => {
    const { x, y, width, height, index } = ref;
    const { revenue } = revenueData[index];
    if (revenue > 0) {
      return (
        <g
          transform={`translate(${x + width / 2},${y + height / 2}) rotate(90)`}
        >
          <text fill="white" textAnchor="middle" dominantBaseline="central">
            {indianRupees(revenue)}
          </text>
        </g>
      );
    } else return null;
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const { positions, agents, revenue, positionCount, profilesClosed } =
        payload[0].payload;
      return (
        <div className="tooltip-container">
          {revenue === 0 && <div>No Revenue Generated Yet</div>}
          <div>
            <strong>{isAdmin ? "Positions: " : "No of Closures: "}</strong>
            <span>{isAdmin ? positions : profilesClosed}</span>
          </div>
          <div>
            <strong>{isAdmin ? "Agents: " : "Active Positions: "}</strong>
            <span>{isAdmin ? agents : positionCount}</span>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="barchart-main-container">
      <ChartMeta
        range={range}
        handleRangeSet={handleRangeSet}
        isAdmin={isAdmin}
      />
      <ResponsiveContainer>
        <BarChart data={revenueData}>
          <XAxis dataKey={isAdmin ? "name" : "company.orgName"} />
          <YAxis
            label={{
              value: "REVENUE",
              angle: 90,
              style: { letterSpacing: "4px" },
            }}
            tick={null}
          />
          <Tooltip content={<CustomTooltip />} />
          <Bar
            dataKey="revenue"
            fill="#a216e0"
            label={renderCustomizedLabel}
            maxBarSize={80}
            radius={[5, 5, 0, 0]}
            minPointSize={5}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
