import { useState, useEffect, useRef } from "react";
import Paper from "@mui/material/Paper";
import "./agentRole.scss";
import DataTable from "../../../components/data-table/table";
import {
  agentRoleColumnNames,
  agentRoleRowFields,
} from "../../../components/data-table/tableData";
import { getCall } from "../../../services/API";
import Search from "../../../components/Search";
import { getDualPaths } from "../../../helper/reuse";
import NavHeader from "../../../components/nav-header/NavigationHeader";

export default function JobRole() {
  const [, cmpId] = getDualPaths();
  const [data, setData] = useState([]);

  const sessionDetails = JSON.parse(localStorage.getItem("nt-session"));
  useEffect(() => {
    getCall("jobrole/agent/" + sessionDetails._id + "/" + cmpId).then((res) => {
      console.log("data ", res.data);
      setData(res.data);
    });
  }, []);

  return (
    <div className="company-main-container">
      <NavHeader show={0}/>
      <Paper className="company-header-section" elevation={0}>
        <Search
          label="Search for Job Role"
          list={data}
          setList={setData}
          reset={null}
          field={agentRoleRowFields[0]}
        />
        <div></div>
      </Paper>
      <DataTable
        columns={agentRoleColumnNames}
        rows={data}
        rowKeys={agentRoleRowFields}
      />
    </div>
  );
}
