import { useNavigate } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import "./nav-header.scss";

export default function NavHeader({ show }) {
  const storedPath = JSON.parse(sessionStorage.getItem("navigationPath")) || [];
  const navigate = useNavigate();
  return (
    <div className="nav-header">
      {show >= 0 && (
        <div
          className="nav-item"
          onClick={() => navigate(storedPath[0]?.navPath)}
        >
          {decodeURI(storedPath[0]?.pathName)}
        </div>
      )}
      {show >= 1 && (
        <>
          <NavigateNextIcon />
          <div
            className="nav-item"
            onClick={() => navigate(storedPath[1]?.navPath)}
          >
            {decodeURI(storedPath[1]?.pathName)}
          </div>
        </>
      )}
      {show >= 2 && (
        <>
          <NavigateNextIcon />
          <div
            className="nav-item"
            onClick={() => navigate(storedPath[2]?.navPath)}
          >
            {decodeURI(storedPath[2]?.pathName)}
          </div>
        </>
      )}
    </div>
  );
}
