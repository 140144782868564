import { useState, useEffect } from "react";
import { Avatar, Paper, ToggleButton, ToggleButtonGroup } from "@mui/material";
import GradeRoundedIcon from "@mui/icons-material/GradeRounded";
import CurrencyRupeeRoundedIcon from "@mui/icons-material/CurrencyRupeeRounded";
import EngineeringRoundedIcon from "@mui/icons-material/EngineeringRounded";
import HomeRepairServiceRoundedIcon from "@mui/icons-material/HomeRepairServiceRounded";
import { postCall } from "../../services/API";
import { dateRanges, indianRupees } from "../../helper/reuse";

export default function CardData() {
  const sessionDetails = JSON.parse(localStorage.getItem("nt-session"));
  const cardList = [
    {
      name: "Revenue",
      icon: <CurrencyRupeeRoundedIcon />,
      color: "yellowgreen",
      when: "Today",
      value: "0",
    },
    {
      name: "Performer",
      icon: <GradeRoundedIcon />,
      color: "deeppink",
      when: "Today",
      value: "None",
    },
    {
      name: "Tool Usage",
      icon: <EngineeringRoundedIcon />,
      color: "coral",
      when: "Today",
      value: "No Data",
    },
    {
      name: "New Roles",
      icon: <HomeRepairServiceRoundedIcon />,
      color: "blueviolet",
      when: "Today",
      value: "0",
    },
  ];

  const [mainCards, setMainCards] = useState(cardList);
  const request = {
    id: sessionDetails._id,
    to: new Date(),
  };

  const getP1Data = async (request) => {
    return await postCall("analytics/p1", request);
  };

  const getCard1Data = (range) => {
    const c1 = { ...request, from: dateRanges(range), type: "revenue" };
    getP1Data(c1).then((res) => {
      const resp = res.data;
      if (resp.length > 0) {
        mainCards[0].value = indianRupees(resp[0].total);
      } else {
        mainCards[0].value = 0;
      }
      setMainCards([...mainCards]);
    });
  };

  const getCard2Data = (range) => {
    const c2 = { ...request, from: dateRanges(range), type: "performer" };
    getP1Data(c2).then((res) => {
      const resp = res.data;
      if (resp.length > 0) {
        mainCards[1].value = resp[0].performer;
      } else {
        mainCards[1].value = "None";
      }
      setMainCards([...mainCards]);
    });
  };

  const getCard3Data = (range) => {
    const c3 = { ...request, from: dateRanges(range), type: "tool" };
    getP1Data(c3).then((res) => {
      const resp = res.data[0];
      if (resp.totalCount) {
        const count =
          Math.ceil((resp.automateCount * 100) / resp.totalCount) + "%";
        mainCards[2].value = count;
      } else {
        mainCards[2].value = "No Data";
      }
      setMainCards([...mainCards]);
    });
  };

  const getCard4Data = (range) => {
    const c4 = { ...request, from: dateRanges(range), type: "role" };
    getP1Data(c4).then((res) => {
      const resp = res.data;
      if (resp.length > 0) {
        mainCards[3].value = resp[0].roleCount;
      } else {
        mainCards[3].value = 0;
      }
      setMainCards([...mainCards]);
    });
  };

  useEffect(() => {
    getCard1Data(cardList[0].when);
    getCard2Data(cardList[1].when);
    getCard3Data(cardList[2].when);
    getCard4Data(cardList[3].when);
  }, []);

  const handleChange = (event, index) => {
    const val = event.target.value;
    mainCards[index].when = val;
    if (index === 0) {
      getCard1Data(val);
    }
    if (index === 1) {
      getCard2Data(val);
    }
    if (index === 2) {
      getCard3Data(val);
    }
    if (index === 3) {
      getCard4Data(val);
    }
  };

  return (
    <div className="p1-data-container">
      {mainCards.map((k, i) => (
        <Paper elevation={4} className="common-paper">
          <div className="container-name">
            <Avatar sx={{ bgcolor: k.color }}>{k.icon}</Avatar>
            <span>{k.name}</span>
          </div>
          <div className="container-value">
            <span>{k.when}: </span>
            <span className="actual-value">{k.value}</span>
          </div>
          <ToggleButtonGroup
            color="primary"
            fullWidth
            onChange={(e) => handleChange(e, i)}
            className="button-group"
          >
            {k.when !== "Today" && (
              <ToggleButton value="Today">Today</ToggleButton>
            )}
            {k.when !== "WTD" && <ToggleButton value="WTD">WTD</ToggleButton>}
            {k.when !== "MTD" && <ToggleButton value="MTD">MTD</ToggleButton>}
            {k.when !== "Total" && (
              <ToggleButton value="Total">Total</ToggleButton>
            )}
          </ToggleButtonGroup>
        </Paper>
      ))}
    </div>
  );
}
