import React, { useState, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ListSubheader from "@mui/material/ListSubheader";
import "./simpledrop.scss";
import { patchCall } from "../../services/API";
import proflies from "../../master/profile.json";
import RightDrawer from "../create-drawer/rightDrawer";
import BasicModal from "../modalPopup/basicModal";
import BilledConfirmation from "../../landing/admin/revenue/billed/billedConfirmation";
import CVUpload from "../cv-upload/cvUpload";

export default function RowDropDown({ rowVal, rowIndex, phase, setRows }) {
  const [state, setState] = useState(false);
  const [confirmState, setConfirmState] = useState(false);
  const [cvState, setCVState] = useState(false);

  const updateOptions = (request) => {
    patchCall("data", request).then((res) => {
      if (phase === "admin") {
        setOptions([...getOptions(request.status.stage)]);

        rowVal[rowIndex].joiningDate = new Date(res.data.joiningDate);
        setRows([...rowVal]);
      }
      setDValue(request.status);
    });
  };

  const toggleState = (isJoined) => {
    if (isJoined === "forJoined") {
      const joinedObj = proflies.p2options.find((f) => f.option === "Joined");
      const request = {
        status: { ...joinedObj },
        rowId: rowVal[rowIndex]._id,
      };
      updateOptions(request);
    }
    setState(!state);
  };

  const billed = () => {
    toggleConfirmedState();
    const billedObj = proflies.p2options.find((f) => f.option === "Billed");
    const request = {
      status: { ...billedObj },
      rowId: rowVal[rowIndex]._id,
    };
    updateOptions(request);
  };

  const toggleConfirmedState = () => {
    setConfirmState(!confirmState);
  };

  const toggleCVState = (status) => {
    if (typeof status === "string" && status.includes("nt-cv-folder")) {
      const billedObj = proflies.p1options.find(
        (f) => f.option === "CV Shared (Admin)"
      );
      setDValue({ ...billedObj });
      rowVal[rowIndex].cvLink = status;
      setRows([...rowVal]);
    }
    setCVState(!cvState);
  };

  const [dvalue, setDValue] = useState(rowVal[rowIndex].status);
  const [options, setOptions] = useState([]);
  const getOptions = (currStage) => {
    if (phase === "agent") return proflies.p1options;
    else {
      return proflies.p2options.filter(
        (h) =>
          h.stage === currStage + (currStage === 2 && 2) ||
          h.stage === currStage + 1
      );
    }
  };

  useEffect(() => {
    setOptions(getOptions(dvalue.stage));
  }, []);

  const onChange = (e) => {
    const request = {
      status: JSON.parse(e.target.value),
      rowId: rowVal[rowIndex]._id,
    };

    if (request.status.option === "Billed") {
      setDValue({ ...dvalue });
      toggleConfirmedState();
    } else if (request.status.option === "Joined") {
      setDValue({ ...dvalue });
      toggleState();
    } else if (request.status.option === "CV Shared (Admin)") {
      setDValue({ ...dvalue });
      toggleCVState();
    } else {
      updateOptions(request);
    }
  };
  const disableMenu = (s) => {
    // when its Joined there is no going back
    if (s.stage === 7 && dvalue.option === "Joined") {
      return true;
    }

    // when its Billed then done
    if (s.stage === 8 && dvalue.option === "Billed") {
      return true;
    }

    // when its CV shared No going back
    if (s.stage === 2 && dvalue.option === "CV Shared (Admin)") {
      return true;
    }

    // very first when val is empty
    if (s.stage === 1 && dvalue === "") {
      return false;
    }
    // enable next step when current step is next
    if (s.stage - 1 === dvalue.stage && dvalue.next) {
      return false;
    }
    //when on current stage
    if (s.stage === dvalue.stage) {
      return false;
    }

    return true;
  };

  const defval = () => {
    if (dvalue === "") return " ";
    if (phase === "agent" && dvalue.stage > 2) {
      //check 11 index for Interested/Ack
      return JSON.stringify(proflies.p1options[11]);
    }
    return JSON.stringify(dvalue);
  };

  return (
    <>
      <FormControl fullWidth size="small" className="main-simple-drop">
        <Select
          value={defval()}
          className="simple-drop-down"
          onChange={onChange}
        >
          <MenuItem value={defval()} className="no-select">
            <em>--Select--</em>
          </MenuItem>
          {options.map((r, i) =>
            r.header ? (
              <ListSubheader className="item-header"> {r.option}</ListSubheader>
            ) : (
              <MenuItem
                value={JSON.stringify(r)}
                key={i}
                disabled={disableMenu(r)}
              >
                {r.option}
              </MenuItem>
            )
          )}
        </Select>
      </FormControl>
      {state && (
        <RightDrawer
          open={state}
          toggle={toggleState}
          eIndex={rowIndex}
          rows={rowVal}
          setRows={setRows}
        />
      )}
      {confirmState && (
        <BasicModal open={confirmState} close={toggleConfirmedState}>
          <BilledConfirmation
            profile={rowVal[rowIndex]}
            revenueToggle={() => {
              toggleConfirmedState();
              toggleState();
            }}
            markBilled={billed}
          />
        </BasicModal>
      )}
      {cvState && (
        <BasicModal open={cvState} close={toggleCVState}>
          <CVUpload jobId={rowVal[rowIndex]._id} closeModal={toggleCVState} />
        </BasicModal>
      )}
    </>
  );
}
