import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Tooltip from "@mui/material/Tooltip";
import Paper from "@mui/material/Paper";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import BusinessIcon from "@mui/icons-material/Business";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import GroupsIcon from "@mui/icons-material/Groups";
import WorkIcon from "@mui/icons-material/Work";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import AnalyticsIcon from '@mui/icons-material/Analytics';
import "./landing.scss";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  agents,
  org,
  userView,
  user,
  company,
  companies,
  earning,
  revenue,
} from "../../../route/paths";
import Header from "../header/header";
import { userRoles } from "../../../helper/constants";
import { getLandingPath } from "../../../helper/reuse";

export default function Landing() {
  const sessionDetails = JSON.parse(localStorage.getItem("nt-session"));
  const drawerWidth = 84;
  const navigate = useNavigate();
  const path = getLandingPath();

  const leftMenuAdmin = [
    { toolTip: "Organization", icon: <CorporateFareIcon />, path: org },
    { toolTip: "Agents", icon: <GroupsIcon />, path: agents },
    { toolTip: "Revenue", icon: <MonetizationOnIcon />, path: revenue },
  ];

  const leftMenuAgent = [
    { toolTip: "Job Roles", icon: <WorkIcon />, path: companies },
  ];

  const leftMenuOperations = [
    { toolTip: "Manage Company", icon: <BusinessIcon />, path: company },
    { toolTip: "Manage Users", icon: <PersonAddIcon />, path: user },
  ];

  const leftMenuOwners = [
    { toolTip: "Dash Board", icon: <AnalyticsIcon />, path: userView },
  ];

  const leftMenu = () => {

    //only show revenue for actual agents
    if (sessionDetails.role === userRoles.agent && !sessionDetails.isAdmin) {
      leftMenuAgent.push({
        toolTip: "Revenue",
        icon: <MonetizationOnIcon />,
        path: earning,
      });
    }
    const userRole = sessionDetails.role;
    if (userRole === userRoles.admin) return leftMenuAdmin;
    if (userRole === userRoles.agent) return leftMenuAgent;
    if (userRole === userRoles.operations) return leftMenuOperations;
    if (userRole === userRoles.owner) return leftMenuOwners;
  };

  const navLeftIcons = (path) => {
    navigate(path);
  };

  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        elevation={0}
      >
        <Header />
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
            borderRight: "none",
          },
        }}
      >
        <Toolbar />
        <Paper
          elevation={0}
          className="left-menu-list"
          style={{
            width: drawerWidth - 0,
          }}
        >
          <Box sx={{ overflow: "auto" }}>
            <List>
              {leftMenu().map((item) => (
                <ListItem key={item.toolTip} disableGutters>
                  <ListItemButton
                    sx={{
                      paddingX: 0,
                      paddingY: 0.5,
                      justifyContent: "center",
                    }}
                    onClick={() => navLeftIcons(item.path)}
                  >
                    <ListItemIcon
                      sx={{ minWidth: 0, padding: 1 }}
                      className={item.path === path ? "selected" : ""}
                    >
                      <Tooltip title={item.toolTip} placement="right">
                        {item.icon}
                      </Tooltip>
                    </ListItemIcon>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </Paper>
      </Drawer>
      <div className="main-store-container">
        <Outlet />
      </div>
    </Box>
  );
}
