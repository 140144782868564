import React, { useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { postCall } from "../../services/API";

export default function AgentMultiDropDown({
  value,
  refValue,
  setValue,
  others,
  setOthers,
  jobId,
}) {
  //  for multi its array always
  const [data, setData] = useState(null);
  const sessionDetails = JSON.parse(localStorage.getItem("nt-session"));
  useEffect(() => {
    const request = {
      adminId: sessionDetails._id,
      jobId: jobId ? jobId : null,
    };
    postCall("users/agents", request).then((res) => {
      console.log("users", res.data);
      sessionDetails.order = 0;
      res.data.unshift(sessionDetails);
      setData(res.data);
    });
  }, []);

  const getAgentsList = (e, l, t, i) => {
    const option = i.option;
    const existsInActual = refValue.find((g) => g._id === option._id);

    if (option.order === 1 || option.order === 0 || existsInActual) {
      const IDIndex = value.findIndex((n) => n._id === option._id);
      if (IDIndex === -1) {
        value.push(option);
      } else {
        value.splice(IDIndex, 1);
      }
      setValue([...value]);
    }

    if (option.order === 2 && !existsInActual) {
      const otherIndex = others.findIndex((n) => n._id === option._id);
      if (otherIndex === -1) {
        others.push(option);
      } else {
        others.splice(otherIndex, 1);
      }
      setOthers([...others]);
    }
  };

  const headers = (order) => {
    if (order === 0) return "Tag Yourself";
    if (order === 1) return "Your Team";
    if (order === 2) return "Assign Others";
  };

  return (
    data && (
      <Autocomplete
        multiple
        options={data}
        defaultValue={value}
        size="small"
        groupBy={(option) => headers(option.order)}
        getOptionDisabled={(option) => option.requestSent}
        isOptionEqualToValue={(option, value) => option._id === value._id}
        onChange={(e, l, t, i) => getAgentsList(e, l, t, i)}
        disableCloseOnSelect
        disableClearable
        getOptionLabel={(option) => option.name}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <div>
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                checked={selected}
              />
              <span>{option.name}</span>
              <span style={{ fontSize: "small", marginLeft: "10px" }}>
                ({option.email})
              </span>
            </div>
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={"Agents"}
            fullWidth
            placeholder="Type to Search"
          />
        )}
      />
    )
  );
}
