export const userRoles = {
  agent: "Recruiter",
  admin: "Admin",
  operations: "Operations",
  owner: "Owner",
};

export const statusOrg = { open: "Open", close: "Close" };

export const statusRole = { open: "Open", hold: "Hold", close: "Close" };

export const clientTypes = { contigent: "Contingent", retainer: "Retainer" };
