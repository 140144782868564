import { useState, useEffect } from "react";
import { patchCall } from "../../../services/API";
import InField from "../../../components/InputField";
import { Button, IconButton, InputAdornment } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AgentRevenueDropDown from "../../../components/dropdown/AgentRevenueDropDown";

export default function DefineRevenue({ toggle, eIndex, rows, setRows }) {
  const creatUpd = eIndex === -1 ? "Define" : "Update";
  const revenue = rows[eIndex].revenue;
  const [revenueDetails, setRevenueDetails] = useState(null);
  const firstTime = revenue.billableCTC === 0 ? true : false;
  const [splits, setSplits] = useState(null);
  const [addShareVal, setAddShareVal] = useState({ user: null, share: "" });

  useEffect(() => {
    setRevenueDetails({
      billable: revenue.billableCTC || "",
      revenue: revenue.revenueShare || "",
    });
    setSplits(revenue.userList);
  }, []);

  const updateVal = (event) => {
    const field = event.target.name;
    const obj = {};
    obj[field] = event.target.value;
    setRevenueDetails((val) => {
      return { ...val, ...obj };
    });
  };

  const AddNewUser = () => {
    splits.push(addShareVal);
    setSplits([...splits]);
    setAddShareVal({ user: null, share: "" });
  };

  const RemoveUser = (i) => {
    splits.splice(i, 1);
    setSplits([...splits]);
  };

  const createOrg = () => {
    const request = {
      id: rows[eIndex]._id,
      billableCTC: revenueDetails.billable,
      revenueShare: revenueDetails.revenue,
      userList: splits.map((c) => {
        c["id"] = c.user._id;
        delete c.user;
        return c;
      }),
    };

    patchCall("data/revenue", request).then((res) => {
      console.log("company", res);
      delete request.id;
      rows[eIndex].revenue = request;
      setRows([...rows]);
      toggle(firstTime ? "forJoined" : undefined);
    });
  };

  const revenueFlag = () => {
    const userListFlag = splits?.reduce((k, p) => k + p.share, 0) === 100;
    const CTCRevenue =
      Number(revenueDetails?.billable) > 0 &&
      Number(revenueDetails?.revenue) > 0;
    return !(userListFlag && CTCRevenue);
  };

  return (
    <div className="create-org-container" style={{ gap: "1.5rem" }}>
      <div className="header" style={{ marginTop: "2rem" }}>
        {creatUpd} Revenue
      </div>
      <InField
        label="Billable CTC"
        type="number"
        value={revenueDetails?.billable}
        onChange={updateVal}
        InputProps={{
          startAdornment: <InputAdornment position="start">₹</InputAdornment>,
        }}
        name="billable"
      />
      <InField
        label="Revenue Share"
        type="number"
        value={revenueDetails?.revenue}
        onChange={updateVal}
        InputProps={{
          startAdornment: <InputAdornment position="start">%</InputAdornment>,
        }}
        name="revenue"
      />
      <div className="revenue-table-container">
        <table>
          <tr>
            <th colspan="3">Revenue Split Ratio</th>
          </tr>
          {splits?.map((p, i) => (
            <tr>
              <td>
                {p?.user?.name} ({p?.user?.email}) - {p?.user?.userType}
              </td>
              <td>{p.share}</td>
              <td>
                <IconButton onClick={() => RemoveUser(i)}>
                  <CloseIcon />
                </IconButton>
              </td>
            </tr>
          ))}
          <tr>
            <td>
              {splits && (
                <AgentRevenueDropDown
                  revenue={addShareVal}
                  splits={splits}
                  setSplits={setSplits}
                  addShare={setAddShareVal}
                  label="Select Contributors"
                  jobId={rows[eIndex].jobrole}
                />
              )}
            </td>
            <td>
              <input
                type="number"
                value={addShareVal.share}
                onChange={(e) =>
                  setAddShareVal({
                    ...addShareVal,
                    share: Number(e.target.value),
                  })
                }
              />
            </td>
          </tr>
        </table>
        <button
          onClick={AddNewUser}
          disabled={!addShareVal.user || !Number(addShareVal.share) > 0}
        >
          Add {addShareVal.user?.name}
        </button>
      </div>

      <Button variant="contained" onClick={createOrg} disabled={revenueFlag()}>
        {creatUpd} Revenue
      </Button>
    </div>
  );
}
