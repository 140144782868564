import React, { useEffect, useState } from "react";
import "./job.scss";
import TextField from "@mui/material/TextField";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

export default function JobDescription({ value, setValue }) {
  const [toogleJob, setToggleJob] = useState(false);
  const [details, setDetails] = useState({
    keyWords: "",
    designation: "",
    location: "",
    education: "",
    experience: {
      min: "",
      max: "",
    },
    ctcRange: {
      min: "",
      max: "",
    },
    noticePeriod: {
      min: "",
      max: "",
    },
  });

  useEffect(() => {
    if (value) {
      setDetails(value);
    } else {
      setValue({ ...details });
    }
  }, []);

  const updateVal = (event) => {
    const field = event.target.name;
    details[field] = event.target.value;
    setValue(details);
  };

  const updateValMinMax = (event) => {
    const numVal = event.target.value;
    if (/^[\d]+$/.test(numVal) || numVal === "") {
      const field = event.target.name.split(".");
      details[field[0]][field[1]] = numVal;
      setValue(details);
    }
  };
  return (
    <div className="job-description-main">
      <div className="job-header" onClick={() => setToggleJob(!toogleJob)}>
        <span>Job Description</span>
        {!toogleJob ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
      </div>
      {toogleJob && (
        <div className="job-content">
          <TextField
            label="Key words"
            size="small"
            fullWidth
            onChange={updateVal}
            name="keyWords"
            value={details.keyWords}
          />

          <div className="job-item">
            <TextField
              label="Designation"
              size="small"
              fullWidth
              onChange={updateVal}
              name="designation"
              value={details.designation}
            />
            <div className="job-right">
              <div className="job-des-title">Experience Range</div>
              <TextField
                label="Min"
                size="small"
                type="number"
                fullWidth
                onChange={updateValMinMax}
                name="experience.min"
                value={details.experience.min}
              />
              <TextField
                label="Max"
                size="small"
                type="number"
                fullWidth
                onChange={updateValMinMax}
                name="experience.max"
                value={details.experience.max}
              />
            </div>
          </div>

          <div className="job-item">
            <TextField
              label="Location"
              size="small"
              fullWidth
              onChange={updateVal}
              name="location"
              value={details.location}
            />
            <div className="job-right">
              <div className="job-des-title">CTC Range</div>
              <TextField
                label="Min"
                size="small"
                type="number"
                fullWidth
                onChange={updateValMinMax}
                name="ctcRange.min"
                value={details.ctcRange.min}
              />
              <TextField
                label="Max"
                size="small"
                type="number"
                fullWidth
                onChange={updateValMinMax}
                name="ctcRange.max"
                value={details.ctcRange.max}
              />
            </div>
          </div>

          <div className="job-item">
            <TextField
              label="Education"
              size="small"
              fullWidth
              onChange={updateVal}
              name="education"
              value={details.education}
            />
            <div className="job-right">
              <div className="job-des-title">Notice Period</div>
              <TextField
                label="Min"
                size="small"
                type="number"
                fullWidth
                onChange={updateValMinMax}
                name="noticePeriod.min"
                value={details.noticePeriod.min}
              />
              <TextField
                label="Max"
                size="small"
                type="number"
                fullWidth
                onChange={updateValMinMax}
                name="noticePeriod.max"
                value={details.noticePeriod.max}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
