import "./chart.scss";
import CardData from "./cardLayout";
import { Paper } from "@mui/material";
import BarRevenueChart from "./barGraph/revenueGraph";
import LineGrowthChart from "./lineGraph/growthGraph";

export default function Charts() {
  return (
    <div className="main-dasboard-container">
      <CardData />
      <div className="chart-paper-container">
        <Paper elevation={3} className="ps-data-container">
          <BarRevenueChart type="admin" />
        </Paper>
        <Paper elevation={3} className="ps-data-container">
          <LineGrowthChart />
        </Paper>
      </div>
      <div
        className="chart-paper-container"
        style={{ padding: "2rem", width: "50%", paddingRight: "1rem" }}
      >
        <Paper elevation={3} className="ps-data-container">
          <BarRevenueChart />
        </Paper>
      </div>
    </div>
  );
}
