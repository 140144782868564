import {
  agentCompany,
  agentRole,
  agents,
  companies,
  company,
  earning,
  extraction,
  jobdetails,
  jobrole,
  org,
  revenue,
  role,
  user,
  userView,
  userdetails,
} from "../route/paths";
import Company from "./operation/company/company";
import Users from "./operation/users/users";
import Organisation from "../landing/admin/organisation/organisation";
import Role from "../landing/admin/role/role";
import Agents from "../landing/admin/agents/agent";
import Charts from "./owner/chart";
import JobRole from "./agent/agentRole/agentRole";
import AgentOrganisation from "./agent/agentOrg/agentOrg";
import AdminAgentOrg from "./admin/agents/adminagentsorg/adminagentOrg";
import AdminAgentJobRole from "./admin/agents/adminagentsrole/adminagentRole";
import ExtractAdminJob from "./admin/extraction/extractJob";
import ExtractAgentAdminJob from "./admin/extraction/extractAgentJob";
import ExtractAgent from "./agent/extraction/extract";
import AgentEarning from "./agent/earning/agentRevenue";
import AdminRevenue from "./admin/revenue/adminRevenue";

export const operationMapping = [
  {
    path: user,
    element: <Users />,
  },
  {
    path: company,
    element: <Company />,
  },
];

export const ownerMapping = [
  {
    path: userView,
    element: <Charts />,
  },
];

export const adminMapping = [
  {
    path: org,
    element: <Organisation />,
  },
  {
    path: role + "/:cmpId",
    element: <Role />,
  },
  {
    path: agents,
    element: <Agents />,
  },
  {
    path: revenue,
    element: <AdminRevenue />,
  },
  {
    path: agentCompany + "/:type/:userId",
    element: <AdminAgentOrg />,
  },
  {
    path: agentRole + "/:type/:agentId/:cmpId",
    element: <AdminAgentJobRole />,
  },
  {
    path: jobdetails + "/:jobId",
    element: <ExtractAdminJob />,
  },
  {
    path: userdetails + "/:agentId/:jobId",
    element: <ExtractAgentAdminJob />,
  },
];

export const agentMapping = [
  {
    path: companies,
    element: <AgentOrganisation />,
  },
  {
    path: earning,
    element: <AgentEarning />,
  },
  {
    path: jobrole + "/:cmpId",
    element: <JobRole />,
  },
  {
    path: extraction + "/:jobId/:userId?",
    element: <ExtractAgent />,
  },
];
