import { useState, useEffect } from "react";
import { getCall, postCall } from "../../../services/API";
import {
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { dateRanges, dbSyncStartDate, indianRupees } from "../../../helper/reuse";
import LineChartMeta from "./lineDataOptions";

export default function LineGrowthChart() {
  const sessionDetails = JSON.parse(localStorage.getItem("nt-session"));
  const [revenueData, setRevenueData] = useState([]);

  const [range, setRange] = useState("0");
  const handleRangeSet = (e) => {
    setRange(e.target.value);
  };

  const [nums, setNums] = useState("5");
  const handleNumSet = (e) => {
    setNums(e.target.value);
  };

  const prepareChartData = (dataList) => {
    const chartData = [];
    let todaysDate = dbSyncStartDate(range);
    for (let m = 0; m < nums; m++) {
      if (m !== 0) {
        const prevDate = new Date(todaysDate);
        if (range === "0") {
          prevDate.setDate(prevDate.getDate() - 7);
          todaysDate = prevDate.toISOString().slice(0, 10);
        }
        if (range === "1") {
          prevDate.setMonth(prevDate.getMonth() - 1);
          todaysDate = prevDate.toISOString().slice(0, 10);
        }
        if (range === "2") {
          prevDate.setDate(prevDate.getDate() - 1);
          todaysDate = prevDate.toISOString().slice(0, 10);
        }
      }
      const revenueDetails = dataList.find(
        // eslint-disable-next-line no-loop-func
        (l) => l.startDate.slice(0, 10) === todaysDate
      );
      chartData.unshift({
        startDate: todaysDate,
        revenue: revenueDetails ? revenueDetails.revenue : 0,
      });
      setRevenueData([...chartData]);
    }
  };

  useEffect(() => {
    const request = {
      id: sessionDetails._id,
      rangeType: range,
      // from: dateRanges(range),
      // to: new Date(),
    };
    //date impact is not shown
    postCall("analytics/timeGrowth", request).then((res) => {
      console.log("response ", res.data);
      prepareChartData(res.data);
    });
  }, [range, nums]);

  const renderCustomizedLabel = (ref) => {
    const { x, y, index } = ref;
    const { revenue } = revenueData[index];
    if (revenue > 0) {
      return (
        <text
          fill="#666"
          x={x}
          y={y}
          fontSize={12}
          textAnchor="end"
          dominantBaseline="central"
        >
          {indianRupees(revenue)}
        </text>
      );
    } else return null;
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const { startDate, revenue } = payload[0].payload;
      return (
        <div className="tooltip-container">
          {revenue === 0 && <div>No Revenue Generated Yet</div>}
          <div>
            <strong>Date: </strong>
            <span>{startDate}</span>
          </div>
        </div>
      );
    }
    return null;
  };

  const formatXAxisTick = (value) => {
    return value.slice(2, 10).split("-").reverse().join("-");
  };

  return (
    <div className="barchart-main-container">
      <LineChartMeta
        range={range}
        handleRangeSet={handleRangeSet}
        nums={nums}
        handleNumSet={handleNumSet}
      />
      <ResponsiveContainer>
        <LineChart data={revenueData}>
          <XAxis dataKey="startDate" tickFormatter={formatXAxisTick} />
          <YAxis
            label={{
              value: "REVENUE",
              angle: 90,
              style: { letterSpacing: "4px" },
            }}
            tick={null}
          />
          <Tooltip content={<CustomTooltip />} />
          <Line
            dataKey="revenue"
            fill="#a216e0"
            label={renderCustomizedLabel}
            maxBarSize={80}
            radius={[5, 5, 0, 0]}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}
