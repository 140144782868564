import { useEffect, useState } from "react";
import "./agent.scss";
import { getCall } from "../../../services/API";
import AgentContainer from "./agent-container/agentBox";

export default function Agents() {
  const [ownAgent, setOwnAgent] = useState([]);
  const [diffAgent, setDiffAgent] = useState([]);

  const sessionDetails = JSON.parse(localStorage.getItem("nt-session"));

  useEffect(() => {
    getCall("users/agents/" + sessionDetails._id).then((res) => {
      const results = res.data[0];
      console.log("agents", results);
      setOwnAgent(
        results.yourAgent.map((p) => {
          p["type"] = "own";
          return p;
        })
      );
      setDiffAgent(
        results.otherAgent.map((p) => {
          p["type"] = "other";
          return p;
        })
      );
    });
  }, []);

  return (
    <div className="admin-agents-container">
      <AgentContainer data={ownAgent} your setData={setOwnAgent} />
      <AgentContainer data={diffAgent} setData={setDiffAgent} />
    </div>
  );
}
