import axios from "axios";

//const domain = "http://localhost:3001/";
const domain = "/"

export const postCall = async (path, request) => {
  return axios.post(domain + path, request);
};

export const getCall = async (path) => {
  return axios.get(domain + path);
};

export const patchCall = async (path, request, isFormData = false) => {
  return axios.patch(domain + path, request, {
    headers: {
      "Content-Type": isFormData ? "multipart/form-data" : "application/json",
    },
  });
};

export const deleteCall = async (path) => {
  return axios.delete(domain + path);
};
