export const columns = [
  "Sl No",
  "Date",
  "Name",
  "Email",
  "Phone",
  "Experience",
  "CTC (ECTC)",
  "Location",
  "Designation",
  "Company",
  "NP",
  "Respone",
  "Status",
  "Action",
];

export const excludedColumnIndex = [9, 10];

export const columnStyles = {
  Col1: {
    minWidth: 70,
    left: 0,
  },
  Col2: {
    minWidth: 130,
    left: 70,
  },
  Col3: {
    minWidth: 200,
    left: 200,
  },
  Col4: {
    minWidth: 250,
    left: 400,
  },
  Col5: {
    minWidth: 120,
    left: 650,
  },
};
