import React, { useState } from "react";
import "./viewJD.scss";
import TextField from "@mui/material/TextField";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

export default function ShowJobDescription({ toogleJob, setToggleJob }) {
  const jobDetails = JSON.parse(sessionStorage.getItem("jobDescription"));

  return (
    <div className="jd-view-main">
      <div className="jd-header" onClick={setToggleJob}>
        <span>Job Description</span>
        {!toogleJob ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
      </div>
      {toogleJob && (
        <div className="jd-content">
          <TextField
            label="Key words"
            size="small"
            fullWidth
            style={{ width: "50%" }}
            InputProps={{
              readOnly: true,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            value={jobDetails.keyWords}
          />
          <TextField
            label="Designation"
            size="small"
            fullWidth
            style={{ width: "25%" }}
            InputProps={{
              readOnly: true,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            value={jobDetails.designation}
          />
          <TextField
            label="Education"
            size="small"
            fullWidth
            style={{ width: "25%" }}
            InputProps={{
              readOnly: true,
            }}
            InputLabelProps={{
              shrink: true,
            }}
            value={jobDetails.education}
          />
          <div className="jd-item">
            <div className="jd-des-title">Experience Range</div>
            <TextField
              label="Min"
              size="small"
              fullWidth
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              value={jobDetails.experience.min}
            />
            <TextField
              label="Max"
              size="small"
              fullWidth
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              value={jobDetails.experience.max}
            />
          </div>
          <div className="jd-item">
            <div className="jd-des-title">CTC Range</div>
            <TextField
              label="Min"
              size="small"
              fullWidth
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              value={jobDetails.ctcRange.min}
            />
            <TextField
              label="Max"
              size="small"
              fullWidth
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              value={jobDetails.ctcRange.max}
            />
          </div>
          <div className="jd-item">
            <div className="jd-des-title">Notice Period</div>
            <TextField
              label="Min"
              size="small"
              fullWidth
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              value={jobDetails.noticePeriod.min}
            />
            <TextField
              label="Max"
              size="small"
              fullWidth
              InputProps={{
                readOnly: true,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              value={jobDetails.noticePeriod.max}
            />
          </div>
        </div>
      )}
    </div>
  );
}
