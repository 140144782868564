import React, { useState, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Popover from "@mui/material/Popover";
import NotificationsIcon from "@mui/icons-material/Notifications";
import IconButton from "@mui/material/IconButton";
import { Button, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import { getCall, postCall } from "../../../../services/API";

const firstText = " has requested ";
const secondText = " of your team to work on a new role kindly approve";
const thirdText = "Organization : ";
const fourthText = "Role : ";

export default function ActionNotification() {
  const sessionDetails = JSON.parse(localStorage.getItem("nt-session"));
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [data, setData] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    getCall("notification/admin/" + sessionDetails._id).then((res) => {
      console.log("notifications", res.data);
      setData(res.data);
    });
  }, []);

  const handleActions = (i, flag) => {
    const notify = data[i];
    const request = {
      id: notify._id,
      adminId: notify.fromUser._id,
      agentId: notify.agentUser._id,
      jobId: notify.jobRole._id,
      flag,
    };
    console.log("notify ", request);
    postCall("notification/admin", request).then((res) => {
      console.log("notification postCall", res.data);
    });
  };

  return (
    <>
      <IconButton
        onClick={handleClick}
        size="small"
        sx={{ ml: 2 }}
        aria-controls={open ? "account-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
      >
        <Avatar sx={{ width: 32, height: 32, bgcolor: "green" }}>
          <NotificationsIcon />
        </Avatar>
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              background: "#e1f5f3 !important",
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <div className="notifications-main-container">
          <div className="notifications-header">
            {data && data?.length > 0 ? "Your Requests" : "No Request"}
          </div>
          {data && data?.length > 0 && (
            <div className="notifications-list">
              {data.map((j, i) => (
                <>
                  <div className="notifications-item">
                    <div className="notification-text">
                      <strong>{j.fromUser.name}</strong>
                      {firstText}
                      <strong>{j.agentUser.name}</strong>
                      {secondText}
                      <br></br>
                      {thirdText}
                      <strong>{j.company.orgName}</strong> <br></br>
                      {fourthText}
                      <strong>{j.jobRole.roleName}</strong>
                    </div>
                    <div className="notification-action">
                      <Button
                        variant="contained"
                        className="notification-approve"
                        onClick={() => handleActions(i, true)}
                      >
                        Approve
                      </Button>
                      <Button
                        variant="contained"
                        className="notification-reject"
                        onClick={() => handleActions(i, false)}
                      >
                        Reject
                      </Button>
                    </div>
                  </div>
                  {data.length > i + 1 && <Divider style={{margin : '0.5rem 0'}}/>}
                </>
              ))}
            </div>
          )}
        </div>
      </Popover>
    </>
  );
}
