import { useState, useEffect } from "react";
import { getCall, patchCall, postCall } from "../../../services/API";
import DropDown from "../../../components/dropdown/Select";
import InField from "../../../components/InputField";
import { Button } from "@mui/material";
import AutoCompleteDropDown from "../../../components/dropdown/AutoDropDown";
import AgentRevenueDropDown from "../../../components/dropdown/AgentRevenueDropDown";
import JobDescription from "../../../components/job-description/setJD/Job";
import { statusRole } from "../../../helper/constants";
import AgentMultiDropDown from "../../../components/dropdown/AgentDropDown";

export default function DefineRole({ eIndex, toggle, rows }) {
  const sessionDetails = JSON.parse(localStorage.getItem("nt-session"));

  const creatUpd = eIndex === -1 ? "Create" : "Update";
  const statusRoleList = Object.values(statusRole);
  const [addAgents, setAddAgents] = useState([]);
  const [roleDetails, setRoleDetails] = useState(
    eIndex === -1
      ? {
          roleName: "",
          pocName: "",
          agents: [],
          description: null,
          status: "",
          company: null,
        }
      : rows[eIndex]
  );
  const [refAgents, setRefAgents] = useState(null);

  const [addShareVal, setAddShareVal] = useState({
    user: eIndex === -1 ? null : rows[eIndex].poc,
    share: "",
  });

  useEffect(() => {
    setRefAgents([...(eIndex === -1 ? [] : rows[eIndex].agents)]);
  }, []);

  const updateVal = (event) => {
    const field = event.target.name;
    const obj = {};
    obj[field] = event.target.value;
    setRoleDetails((val) => {
      return { ...val, ...obj };
    });
  };

  const multiDropDown = (value) => {
    setRoleDetails((val) => {
      return { ...val, agents: value };
    });
  };

  const CompanyDropDown = (value) => {
    setRoleDetails((val) => {
      return { ...val, company: value };
    });
  };

  const jobDescript = (value) => {
    setRoleDetails((val) => {
      return { ...val, description: value };
    });
  };

  const createRole = () => {
    const request = {
      roleName: roleDetails.roleName,
      pocName: addShareVal.user._id,
      positions: roleDetails.positions,
      description: roleDetails.description,
      status: roleDetails.status,
      agents: roleDetails.agents.map((l) => l._id),
      otherAgents: addAgents.map((l) => l._id),
    };
    if (eIndex === -1) {
      const orgID = roleDetails.company._id;
      postCall("jobrole/" + orgID + "/" + sessionDetails._id, request).then(
        (res) => {
          console.log("createjob", res);
          toggle("success");
        }
      );
    } else {
      patchCall("jobrole/" + rows[eIndex]._id, request).then((res) => {
        console.log("createjob", res);
        toggle("success");
      });
    }
  };

  return (
    <div className="create-org-container" style={{ gap: "1rem" }}>
      <div className="header" style={{ marginTop: "0.5rem" }}>
        {creatUpd} Role
      </div>
      <AutoCompleteDropDown
        label="Organisation"
        //from BE I was not able to set to company check lookups
        value={roleDetails.company || roleDetails.companies}
        setValue={CompanyDropDown}
        disableDrop={eIndex !== -1}
      />
      <InField
        label="Role Name"
        value={roleDetails.roleName}
        onChange={updateVal}
        name="roleName"
      />
      <AgentRevenueDropDown
        revenue={addShareVal}
        splits={[]}
        addShare={setAddShareVal}
        label="POC Name"
      />
      <AgentMultiDropDown
        value={roleDetails.agents}
        refValue={refAgents}
        setValue={multiDropDown}
        others={addAgents}
        setOthers={setAddAgents}
        jobId={rows[eIndex]?._id}
      />
      <InField
        label="No of Positions"
        type="number"
        value={roleDetails.positions}
        onChange={updateVal}
        name="positions"
      />
      <DropDown
        label="Status"
        options={statusRoleList}
        value={roleDetails.status}
        setValue={updateVal}
        name="status"
      />
      <JobDescription value={roleDetails.description} setValue={jobDescript} />
      <Button variant="contained" onClick={createRole}>
        {creatUpd} role
      </Button>
    </div>
  );
}
