import { useEffect } from "react";
import "./table.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import RowDropDown from "../dropdown/SimpleDropDown";
import ReadMoreIcon from "@mui/icons-material/ReadMore";
import {
  getDualPaths,
  getLandingPath,
  getStandardDate,
  jobDescriptionProvided,
} from "../../helper/reuse";
import {
  admin,
  agent,
  agentCompany,
  agentRole,
  agents,
  companies,
  company,
  extraction,
  jobdetails,
  jobrole,
  org,
  role,
  userdetails,
} from "../../route/paths";
import { useNavigate } from "react-router-dom";
import { userRoles } from "../../helper/constants";
import { Tooltip } from "@mui/material";
import { setDescription, setPath } from "../../helper/navpath";

export default function DataTable({
  columns,
  rows,
  addToggle,
  rowKeys,
  eIndexSet,
  setRows,
  setShowMore,
}) {
  const path = getLandingPath();
  const [path1, path2] = getDualPaths();
  let q = 1;
  useEffect(() => {
    q = 1;
  }, [rows]);

  const navigate = useNavigate();
  const sessionDetails = JSON.parse(localStorage.getItem("nt-session"));
  const tagStatus = (status) => {
    return (
      <div
        className="row-status"
        style={{ backgroundColor: status ? "#198754" : "#ff3333" }}
      >
        {status ? "Tagged" : "Pending"}
      </div>
    );
  };

  const renderValue = (row, key) => {
    // below condition for agentRoleRowFields (check)
    if (key.includes(".")) {
      const keys = key.split(".");
      return row[keys[0]][keys[1]];
    }

    if (key === "joiningDate" || key === "createdAt") {
      return getStandardDate(row[key]);
    }
    if (path !== company) {
      if (key === "orgName" || key === "roleName" || key === "name")
        return <div className="main-table-item">{row[key]}</div>;
    }
    if (key === "description")
      return tagStatus(jobDescriptionProvided(row[key]));
    return row[key];
  };

  const openSection = (i, j) => {
    if (sessionDetails.role === userRoles.admin) {
      if (path === org && j === 0) {
        setPath(0, rows[i].orgName);
        navigate(admin + "/" + role + "/" + rows[i]._id);
      }
      if (path === role && j === 1) {
        setPath(1, rows[i].roleName);
        navigate(admin + "/" + jobdetails + "/" + rows[i]._id);
      }
      if (path === agents && j === 0) {
        setPath(0, rows[i].name);
        navigate(
          admin + "/" + agentCompany + "/" + rows[i].type + "/" + rows[i]._id
        );
      }
      if (path === agentCompany && j === 0) {
        setPath(1, rows[i].orgName);
        navigate(
          admin +
            "/" +
            agentRole +
            "/" +
            path1 +
            "/" +
            path2 +
            "/" +
            rows[i]._id
        );
      }
      if (path === agentRole && j === 0) {
        setPath(2, rows[i].roleName);
        navigate(admin + "/" + userdetails + "/" + path1 + "/" + rows[i]._id);
      }
    } else {
      if (path === companies && j === 0) {
        setPath(0, rows[i].orgName);
        navigate(agent + "/" + jobrole + "/" + rows[i]._id);
      }
      if (path === jobrole && j === 0) {
        setPath(1, rows[i].roleName);
        setDescription(rows[i].description);
        navigate(agent + "/" + extraction + "/" + rows[i]._id);
      }
    }
  };

  const editRow = (i) => {
    eIndexSet(i);
    addToggle();
  };

  return (
    // add below flags to give paper feature  component={Paper}  elevation={0}
    <TableContainer className="main-table-container">
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell align="center" className="first-last-column">
              <div>Sl No</div>
            </TableCell>
            {columns.map((column) => (
              <TableCell align="center" key={column} className="overflow-cell">
                <Tooltip title={column} placement="top">
                  {column}
                </Tooltip>
              </TableCell>
            ))}
            {setShowMore && (
              <TableCell align="center" className="first-last-column">
                <div>More</div>
              </TableCell>
            )}
            {addToggle && (
              <TableCell align="center" className="first-last-column">
                <div>Action</div>
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(
            (row, index) =>
              !row.hide && (
                <TableRow key={index}>
                  <TableCell align="center" className="first-last-column">
                    <div className="cell-data">{q++}</div>
                  </TableCell>
                  {rowKeys.map((key, num) => (
                    <TableCell
                      align="center"
                      key={num}
                      className="rem-field"
                      onClick={() => openSection(index, num)}
                    >
                      {key.includes("drop") ? (
                        <div className="cell-data">
                          <RowDropDown
                            rowVal={rows}
                            rowIndex={index}
                            phase="admin"
                            setRows={setRows}
                          />
                        </div>
                      ) : (
                        <div className="cell-data">{renderValue(row, key)}</div>
                      )}
                    </TableCell>
                  ))}
                  {setShowMore && (
                    <TableCell align="center" className="first-last-column">
                      <div className="cell-data">
                        <IconButton onClick={() => setShowMore(index)}>
                          <ReadMoreIcon />
                        </IconButton>
                      </div>
                    </TableCell>
                  )}
                  {addToggle && (
                    <TableCell align="center" className="first-last-column">
                      <div className="cell-data">
                        <IconButton onClick={() => editRow(index)}>
                          <EditIcon />
                        </IconButton>
                      </div>
                    </TableCell>
                  )}
                </TableRow>
              )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
