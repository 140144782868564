import { Modal } from "@mui/material";
import "./basicmodal.scss";

export default function BasicModal({ open, close, children }) {
  return (
    <Modal open={open} onClose={close}>
      <div className="main-modal-container">{children}</div>
    </Modal>
  );
}
