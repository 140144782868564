import { useState, useEffect, useRef } from "react";
import { deleteCall, patchCall, postCall } from "../../../services/API";
import "./users.scss";
import RightDrawer from "../../../components/create-drawer/rightDrawer";
import AutoCompleteDropDown from "../../../components/dropdown/AutoDropDown";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ContainedButton from "../../../components/ContainedButton";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import Button from "@mui/material/Button";
import { userRoles } from "../../../helper/constants";
import BasicModal from "../../../components/modalPopup/basicModal";

export default function Users() {
  const [data, setData] = useState([]);
  const [state, setState] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(null);
  // reference to closure check it
  const delayedFunctionRef = useRef();
  const [tagUser, setTagUser] = useState(null);
  const [updateUser, setUpdateUser] = useState(null);
  const [expandIndex, setExpandIndex] = useState({
    pIndex: -1,
    cIndex: -1,
    tag: false,
  });

  const legends = [
    {
      text: "Owner/AVP",
      code: "#FF5733",
    },
    {
      text: "Admin",
      code: "#334FFF",
    },
    {
      text: "Agent",
      code: "#FF33CE",
    },
  ];

  const toggleDrawer = () => {
    setState(!state);
  };

  const closeDelete = () => {
    setDeleteConfirm(null);
  };

  useEffect(() => {
    const request = {
      type: userRoles.owner,
    };
    postCall("users/filter", request).then((res) => {
      console.log("owners", res.data);
      setData(res.data);
    });
  }, []);

  const expandList = (user, type, index) => {
    if (type) {
      //reset owner list
      if (expandIndex.pIndex > -1 && expandIndex.pIndex !== index) {
        data[expandIndex.pIndex].expand = false;
        if (expandIndex.cIndex > -1) {
          data[expandIndex.pIndex].employee[expandIndex.cIndex].expand = false;
        }
        expandIndex.cIndex = -1;
      }
      expandIndex.pIndex = index;
    } else {
      //reset admins
      if (expandIndex.cIndex > -1 && expandIndex.cIndex !== index) {
        data[expandIndex.pIndex].employee[expandIndex.cIndex].expand = false;
      }
      expandIndex.cIndex = index;
    }

    if (expandIndex.tag) {
      expandIndex.tag = false;
    }
    setExpandIndex({ ...expandIndex });

    user.expand = !user.expand;

    if (!user.opened) {
      const request = {
        under: user._id,
      };
      postCall("users/filter", request)
        .then((res) => {
          user.employee = [].concat(res.data);
          setData([...data]);
        })
        .catch((err) => {
          setData([...data]);
        });

      user.opened = true;
    }
  };

  const tagAdmin = (user, type, index) => {
    // this is applicable for both user types and else do same as below
    if (!user.expand) {
      expandList(user, type, index);
    }
    expandIndex.tag = true;
    setExpandIndex({ ...expandIndex });
  };

  const cancelTagging = () => {
    // agent side there was issue "Add Admin" opening in "Agent Section"
    if (expandIndex.cIndex > -1 && expandIndex.pIndex > -1) {
      data[expandIndex.pIndex].employee[expandIndex.cIndex].expand = false;
      setData([...data]);
      expandIndex.cIndex = -1;
    }
    expandIndex.tag = false;
    setExpandIndex({ ...expandIndex });
  };

  const AdminDropDown = (value) => {
    if (value) {
      setTagUser(value);
    } else {
      setTagUser(null);
    }
  };

  const createUser = () => {
    cancelTagging();
    setUpdateUser(null);
    toggleDrawer();
  };

  const editUser = (user, role) => {
    cancelTagging();
    user.role = role;
    setUpdateUser(user);
    toggleDrawer();
  };

  const unTagUser = (child, parent, childIndex) => {
    const request = {
      parentId: parent._id,
      childId: child._id,
      tag: false,
    };
    patchCall("users/tag", request).then((res) => {
      console.log("users ", res);
      parent.employee.splice(childIndex, 1);
      setData([...data]);
      cancelTagging();
    });
  };

  const deleteUser = (user, aId, bId, cId) => {
    setDeleteConfirm(user.name);
    delayedFunctionRef.current = () => {
      deleteCall("users/" + user._id).then((res) => {
        closeDelete();
        cancelTagging();
        console.log("delte users ", res.data);
        if (bId === undefined && cId === undefined) {
          data.splice(aId, 1);
        } else if (cId === undefined) {
          data[aId].employee.splice(bId, 1);
        } else {
          data[aId].employee[bId].employee.splice(cId, 1);
        }
        setData([...data]);
      });
    };
  };

  const taggAdminId = (user) => {
    const request = {
      parentId: user._id,
      childId: tagUser._id,
      tag: true,
    };
    patchCall("users/tag", request).then((res) => {
      console.log("users ", res);
      user.employee.push(tagUser);
      setData([...data]);
      setTagUser(null);
      cancelTagging();
    });
  };
  //https://two-wrongs.com/draw-a-tree-structure-with-only-css.html
  return (
    <div className="users-main-container">
      <div className="tagging-container">
        <Button variant="contained" onClick={createUser} className="add-user">
          Add User
        </Button>
        <div className="users-tree-model">
          {data.map((o, p) => (
            <div className="lines">
              <div className="users-title-menu">
                <div
                  onClick={() => expandList(o, true, p)}
                  className="name-box"
                  style={{ color: legends[0].code }}
                >
                  {o.expand ? <ExpandMoreIcon /> : <NavigateNextIcon />}
                  {o.name}
                </div>
                {!expandIndex.tag && (
                  <div className="action-items">
                    <Button onClick={() => tagAdmin(o, true, p)}>
                      <ControlPointIcon /> Add Admin
                    </Button>
                    <Button onClick={() => editUser(o, userRoles.owner)}>
                      <ModeEditIcon /> Edit
                    </Button>
                    <Button onClick={() => deleteUser(o, p)}>
                      <DeleteForeverIcon /> Delete
                    </Button>
                  </div>
                )}
              </div>
              {o.expand && (
                <div className="users-child-list">
                  {o.employee?.map((a, c) => (
                    <div className="lines">
                      <div className="users-title-menu">
                        <div
                          onClick={() => expandList(a, false, c)}
                          className="name-box"
                          style={{ color: legends[1].code }}
                        >
                          {a.expand ? <ExpandMoreIcon /> : <NavigateNextIcon />}
                          {a.name}
                        </div>
                        {!expandIndex.tag && (
                          <div className="action-items">
                            <Button onClick={() => tagAdmin(a, false, c)}>
                              <ControlPointIcon /> Add Agent
                            </Button>
                            <Button
                              onClick={() => editUser(a, userRoles.admin)}
                            >
                              <ModeEditIcon /> Edit
                            </Button>
                            <Button onClick={() => unTagUser(a, o, c)}>
                              <LinkOffIcon /> UnTag
                            </Button>
                            <Button onClick={() => deleteUser(a, p, c)}>
                              <DeleteForeverIcon /> Delete
                            </Button>
                          </div>
                        )}
                      </div>
                      {a.expand && (
                        <div className="users-child-list">
                          {a.employee?.map((g, k) => (
                            <div className="lines">
                              <div className="users-title-menu">
                                <div
                                  className="name-box"
                                  style={{ color: legends[2].code }}
                                >
                                  {g.name}
                                </div>
                                {!expandIndex.tag && (
                                  <div className="action-items">
                                    <Button
                                      onClick={() =>
                                        editUser(g, userRoles.agent)
                                      }
                                    >
                                      <ModeEditIcon /> Edit
                                    </Button>
                                    <Button onClick={() => unTagUser(g, a, k)}>
                                      <LinkOffIcon /> UnTag
                                    </Button>
                                    <Button
                                      onClick={() => deleteUser(g, p, c, k)}
                                    >
                                      <DeleteForeverIcon /> Delete
                                    </Button>
                                  </div>
                                )}
                              </div>
                            </div>
                          ))}
                          {expandIndex.tag && (
                            <div className="tagging-menu">
                              <AutoCompleteDropDown
                                label="Available Agents"
                                setValue={AdminDropDown}
                                disableDrop={false}
                              />
                              <ContainedButton
                                onClick={() => taggAdminId(a)}
                                disabled={!tagUser}
                              >
                                Tag
                              </ContainedButton>
                              <ContainedButton onClick={cancelTagging}>
                                Cancel
                              </ContainedButton>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  ))}
                  {expandIndex.tag && expandIndex.cIndex === -1 && (
                    <div className="tagging-menu">
                      <AutoCompleteDropDown
                        label="Available Admins"
                        setValue={AdminDropDown}
                        disableDrop={false}
                      />
                      <ContainedButton
                        onClick={() => taggAdminId(o)}
                        disabled={!tagUser}
                      >
                        Add
                      </ContainedButton>
                      <ContainedButton onClick={cancelTagging}>
                        Cancel
                      </ContainedButton>
                    </div>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

      <div className="color-codes">
        <div className="color-header">Color Code Legend</div>
        <div className="items-list">
          {legends.map((h) => (
            <div className="items">
              <div className="color" style={{ backgroundColor: h.code }} />
              <div>{h.text}</div>
            </div>
          ))}
        </div>
      </div>
      {state && (
        <RightDrawer
          open={state}
          toggle={toggleDrawer}
          rows={data}
          eIndex={updateUser}
          setRows={setData}
        />
      )}
      <BasicModal open={deleteConfirm !== null} onClose={closeDelete}>
        <div>
          <div>
            Are you sure want to delete <strong>{deleteConfirm}</strong>?
          </div>
          <div>
            <Button onClick={closeDelete}>No</Button>
            <Button onClick={() => delayedFunctionRef.current()}>Yes</Button>
          </div>
        </div>
      </BasicModal>
    </div>
  );
}
