import { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import "./agentOrg.scss";
import DataTable from "../../../components/data-table/table";
import {
  agentOrgColumnNames,
  agentOrgRowFields,
} from "../../../components/data-table/tableData";
import { getCall } from "../../../services/API";
import Search from "../../../components/Search";

export default function AgentOrganisation() {
  const [data, setData] = useState([]);

  const sessionDetails = JSON.parse(localStorage.getItem("nt-session"));
  useEffect(() => {
    getCall("company/agent/" + sessionDetails._id).then((res) => {
      console.log("data ", res.data);
      setData(res.data);
    });
  }, []);

  return (
    <div className="company-main-container">
      <Paper className="company-header-section" elevation={0}>
        <Search
          label="Search for Organisation"
          list={data}
          setList={setData}
          reset={null}
          field={agentOrgRowFields[0]}
        />
        <div></div>
      </Paper>
      <DataTable
        columns={agentOrgColumnNames}
        rows={data}
        rowKeys={agentOrgRowFields}
      />
    </div>
  );
}
