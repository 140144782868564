//admin
export const admin = "/admin";
export const org = "organisation";
export const role = "role";
export const agents = "agents";
export const jobdetails = "jobdetails";
export const userdetails = "userdetails";
export const agentCompany = "agentCompany";
export const revenue = "revenue";
export const agentRole = "agentRole";

//agent
export const agent = "/agent";
export const jobrole = "jobrole";
export const companies = "companies";
export const earning = "earning";
export const extraction = "extract";

//operation
export const operation = "/operation";
export const user = "user";
export const company = "company";

//owner
export const owner = "/owner";
export const userView = "users";