import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { getCall, postCall } from "../../services/API";
import { userRoles } from "../../helper/constants";

export default function AgentRevenueDropDown({
  revenue,
  splits,
  addShare,
  setSplits,
  label,
  jobId,
}) {
  const [data, setData] = useState(null);
  useEffect(() => {
    if (jobId) {
      getCall("jobrole/details/" + jobId).then((res) => {
        console.log("details", res.data[0], splits);
        const users = res.data[0];
        const usersList = users.holders.map((h) => {
          let userType = "Agent";
          if (h._id === users.pocName) {
            userType = "POC";
          }
          if (h._id === users.user) {
            userType = "Admin";
          }
          h.userType = userType;

          //below logic is to update splits array for showing details
          const shareUser = splits.find((t) => t.id === h._id);
          if (shareUser) {
            shareUser.user = h;
            delete shareUser.id;
          }
          return h;
        });
        setSplits([...splits]);
        setData(usersList);
      });
    } else {
      const AdminRequest = {
        type: userRoles.admin,
      };
      const AgentRequest = {
        type: userRoles.agent,
      };
      Promise.allSettled([
        postCall("users/filter", AdminRequest),
        postCall("users/filter", AgentRequest),
      ]).then((res) => {
        console.log("users", res);
        const admins = res[0].value.data.map((l) => {
          l.type = 1;
          return l;
        });
        const agents = res[1].value.data;
        const allUsers = admins.concat(agents);
        setData(allUsers);
      });
    }
  }, []);

  const getAgentsList = (item) => {
    if (item) {
      addShare({ ...revenue, user: item });
    } else {
      addShare({ ...revenue, user: null });
    }
  };

  return (
    data && (
      <Autocomplete
        options={data}
        size="small"
        value={revenue.user}
        groupBy={(option) => {
          if (jobId) return "";
          else return option.type === 1 ? "Admin" : "Agent";
        }}
        isOptionEqualToValue={(option, value) => option._id === value._id}
        onChange={(e, l) => getAgentsList(l)}
        getOptionLabel={(option) => option.name}
        filterOptions={(options) => {
          return options.filter((k) => {
            // below condition if Admin exists don't show POC and vice versa
            const adminExists = splits.find((g) => g.user.userType === "Admin");
            const POCExists = splits.find((g) => g.user.userType === "POC");
            if(k.userType === "POC" && !POCExists){
              return !adminExists;
            }
            if(k.userType === "Admin" && !adminExists){
              return !POCExists;
            }
            return !splits.some((g) => g.user._id === k._id);
          });
        }}
        disableClearable
        renderOption={(props, option) => (
          <li {...props}>
            <div>
              <span>{option.name}</span>
              <span style={{ fontSize: "small", marginLeft: "10px" }}>
                ({option.email})
              </span>
              {option.userType && (
                <span style={{ fontSize: "small", marginLeft: "10px" }}>
                  - {option.userType}
                </span>
              )}
            </div>
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            fullWidth
            placeholder="Type to Search"
          />
        )}
      />
    )
  );
}
