import { useState, useEffect } from "react";
import { patchCall, postCall } from "../../../services/API";
import DropDown from "../../../components/dropdown/Select";
import InField from "../../../components/InputField";
import { userRoles } from "../../../helper/constants";
import { Alert, Button, Snackbar } from "@mui/material";

export default function DefineUsers({
  toggle,
  userList,
  setUserList,
  updateUser,
}) {
  const creatUpd = !updateUser ? "Create" : "Update";
  const accessRoles = Object.values(userRoles);
  const [open, setOpen] = useState("");

  const [userDetails, setUserDetails] = useState({
    name: updateUser ? updateUser.name : "a",
    email: updateUser ? updateUser.email : "@gmail.com",
    password: "1234",
    role: updateUser ? updateUser.role : "",
    employee: [],
    otherEmployee: [],
  });

  const updateVal = (event) => {
    const field = event.target.name;
    const obj = {};
    obj[field] = event.target.value;
    setUserDetails((val) => {
      return { ...val, ...obj };
    });
  };

  const createOrg = () => {
    const request = {
      name: userDetails.name,
      email: userDetails.email,
      password: userDetails.password,
      role: userDetails.role,
      employee: userDetails.employee,
    };

    if (!updateUser) {
      postCall("users", request)
        .then((res) => {
          console.log("res ", res);
          if (res.data.statusCode === 400) {
            setOpen(res.data.message);
            return;
          }
          if (userDetails.role === userRoles.owner) {
            userList.push(res.data);
            setUserList(userList);
          }
          toggle();
        })
        .catch((err) => {
          console.log("err ", err);
        });
    } else {
      const userRequest = {
        id: updateUser._id,
        name: userDetails.name,
      };
      patchCall("users/profile", userRequest).then((res) => {
        console.log("company", res);
        updateUser.name = userDetails.name;
        setUserList(userList);
        toggle();
      });
    }
  };

  return (
    <>
      <div className="create-org-container" style={{ gap: "2rem" }}>
        <div className="header" style={{ marginTop: "2rem" }}>
          {creatUpd} User
        </div>
        <InField
          label="Name"
          value={userDetails.name}
          onChange={updateVal}
          name="name"
        />
        <InField
          label="Email"
          type="email"
          value={userDetails.email}
          onChange={updateVal}
          disabled={updateUser}
          name="email"
        />
        <DropDown
          label="Role"
          options={accessRoles}
          value={userDetails.role}
          setValue={updateVal}
          disabled={updateUser}
          name="role"
        />
        <Button variant="contained" onClick={createOrg}>
          {creatUpd} User
        </Button>
      </div>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={open}
        onClose={() => setOpen(false)}
      >
        <Alert onClose={() => setOpen(false)} severity="error" variant="filled">
          {open}
        </Alert>
      </Snackbar>
    </>
  );
}
