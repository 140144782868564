export const getLandingPath = () => {
  const fullPath = window.location.pathname;
  const path = fullPath.split("/");
  return path[2];
};

export const getParams = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const params = [];
  searchParams.forEach((p, v) => {
    params.push({ [v]: p });
  });
  return params;
};

export const getDualPaths = () => {
  const fullPath = window.location.pathname;
  const path = fullPath.split("/");
  return path.slice(-2);
};

export const getRequiredPaths = (sliceIndex) => {
  const fullPath = window.location.pathname;
  const path = fullPath.split("/");
  return path.slice(sliceIndex);
};

export const getDateTime = (date) => {
  const extractDate = new Date(date);
  const month = extractDate.toLocaleString("default", { month: "long" });
  const fullDate =
    extractDate.getDate() + " " + month + " " + extractDate.getFullYear();
  const fullTime = extractDate.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  return fullDate + " " + fullTime;
};

export const getStandardDate = (date) => {
  const extractDate = new Date(date);
  let month = extractDate.getMonth() + 1;
  if (month < 10) month = "0" + month;
  let dateNum = extractDate.getDate();
  if (dateNum < 10) dateNum = "0" + dateNum;
  const year = extractDate.getFullYear().toString().substring(2);
  return dateNum + "-" + month + "-" + year;
};

export const jobDescriptionProvided = (request) => {
  const val = Object.keys(request);
  for (let j = 0; j < val.length; j++) {
    const objValue = request[val[j]];
    if (typeof objValue === "object") {
      if (objValue.max.length === 0 || objValue.min.length === 0) {
        return false;
      }
    } else {
      if (objValue.length === 0) {
        return false;
      }
    }
  }
  return true;
};

export const indianRupees = (amount) => {
  const formattedAmount = amount.toLocaleString("en-IN", {
    style: "currency",
    currency: "INR",
  });

  return formattedAmount.slice(1, -3);
};

export const dateRanges = (type) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  if (type === "Today") {
    return today;
  }
  if (type === "WTD") {
    const startDate = new Date(today);
    startDate.setDate(today.getDate() - today.getDay());
    return startDate;
  }
  if (type === "MTD") {
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    return startOfMonth;
  }
  if (type === "Total") {
    return new Date(2020, 0, 1);
  }
  return null;
};

export const dbSyncStartDate = (range) => {
  const date = new Date();
  date.setMinutes(date.getMinutes() - 330);
  if (range === "2") return date.toISOString().slice(0, 10);
  if (range === "0") {
    const dayOfWeek = date.getDay();
    const diff = date.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1);
    const startOfWeek = new Date(date.setDate(diff));
    return startOfWeek.toISOString().slice(0, 10);
  }
  if (range === "1") {
    const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 2);
    return startOfMonth.toISOString().slice(0, 10);
  }
};
