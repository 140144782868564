import { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import { Modal, TextField } from "@mui/material";
import DataTable from "../../../components/data-table/table";
import {
  extractAgentJobColumnNames,
  extractAgentJobRowData,
} from "../../../components/data-table/tableData";
import "./extract.scss";
import { getDualPaths } from "../../../helper/reuse";
import { getCall } from "../../../services/API";
import NavHeader from "../../../components/nav-header/NavigationHeader";
import ProfileMoreDetails from "./profileDetails";

export default function ExtractAgentAdminJob() {
  const [agentId, jobid] = getDualPaths();
  const [data, setData] = useState([]);
  const [showMore, setShowMore] = useState(-1);

  useEffect(() => {
    getCall("data/extract/agent/" + agentId + "/" + jobid).then((res) => {
      console.log("details ", res.data);
      setData(res.data);
    });
  }, []);

  return (
    <>
      <div className="admin-extraction-container">
        <NavHeader show={2} />
        <Paper className="admin-extraction-header" elevation={0}>
          <TextField size="small" label="Date" type="date" focused />
        </Paper>
        <DataTable
          columns={extractAgentJobColumnNames}
          rows={data}
          rowKeys={extractAgentJobRowData}
          setShowMore={setShowMore}
        />
      </div>
      {showMore > -1 && (
        <Modal
        open={showMore > -1}
        onClose={() => setShowMore(-1)}
      >
        <ProfileMoreDetails profileData={data[showMore]} />
      </Modal>
      )}
    </>
  );
}
