import { useState, useEffect, useRef } from "react";
import { IconButton, Paper } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import InfoIcon from "@mui/icons-material/Info";
import TextField from "@mui/material/TextField";
import "./agentdetails.scss";
import { useParams } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { getCall, patchCall, postCall } from "../../../services/API";
import { getStandardDate } from "../../../helper/reuse";
import RowDropDown from "../../../components/dropdown/SimpleDropDown";
import ProfileDetails from "./profileDetails/profileDetails";
import { columnStyles, columns, excludedColumnIndex } from "./constants";
import NavHeader from "../../../components/nav-header/NavigationHeader";
import ShowJobDescription from "../../../components/job-description/viewJD/ViewJobDescription";

export default function ExtractAgent() {
  const sessionDetails = JSON.parse(localStorage.getItem("nt-session"));
  const [data, setData] = useState(null);
  const [open, setOpen] = useState(-1);
  const [csvData, setCSVData] = useState(null);
  const [toogleJob, setToggleJob] = useState(false);
  const tableContainer = useRef();
  const params = useParams();
  const jobID = params.jobId;
  const userID = params.userId ? params.userId : sessionDetails._id;
  const sortableColumns = [1, 2, 3, 4];
  const [sortInfo, setSortInfo] = useState(null);

  const getData = () => {
    getCall("data/" + jobID + "/" + userID).then((res) => {
      console.log("details ", res.data);
      setData(res.data);
      //if no data found response is not received check condition as well
      if (res?.data?.length > 0) {
        const interval = setInterval(() => {
          const tableRef = tableContainer.current;
          tableRef.scrollLeft = tableRef.scrollWidth;
          if (tableRef.scrollLeft > 0) {
            clearInterval(interval);
          }
        }, 100);
        return () => clearInterval(interval);
      }
    });
  };

  useEffect(() => {
    getCall("data/" + jobID + "/" + userID).then((res) => {
      console.log("details ", res.data);
      setData(res.data);
      //if no data found response is not received check condition as well
      if (res?.data?.length > 0) {
        const interval = setInterval(() => {
          const tableRef = tableContainer.current;
          tableRef.scrollLeft = tableRef.scrollWidth;
          if (tableRef.scrollLeft > 0) {
            clearInterval(interval);
          }
        }, 100);
        return () => clearInterval(interval);
      }
    });
  }, []);

  const columnStyle = (i) => {
    if (i < 5) return columnStyles["Col" + (i + 1)];
  };

  const focusOut = (value, pIndex, id) => {
    const request = {
      text: value,
      rowId: id,
      index: pIndex,
    };
    patchCall("data", request).then((res) => {
      console.log("patchCall", res);
    });
  };

  const addRow = () => {
    const rowData = new Array(11).fill("");
    const request = {
      data: [{ extractData: rowData }],
      user: sessionDetails._id,
      jobrole: jobID,
      sourceType: 3,
    };
    postCall("data", request).then((res) => {
      console.log("postCall", res);
      getData();
    });
  };

  const uploadFile = () => {
    const rowData = csvData
      .filter((k) => k.length === 11) // gets only fullcolumn values
      .slice(1) // remove last row which usually have single value
      .map((h) => {
        return { extractData: h };
      });
    const request = {
      data: rowData,
      user: sessionDetails._id,
      jobrole: jobID,
      sourceType: 2,
    };
    postCall("data", request).then((res) => {
      console.log("postCall", res);
      getData();
    });
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const content = e.target.result;
      const rows = content.split(/\r\n|\n/);
      const data = rows.map((row) => row.split(","));
      setCSVData(data);
    };
    reader.readAsText(file);
  };

  const getValues = (i, item) => {
    if (i === 1) return new Date(item.createdAt);
    if (i === 2) return item.candidate[0];
    if (i === 3) return item.candidate[1];
    if (i === 4) return item.candidate[2];
  };

  const sortColumns = (i) => {
    if (sortableColumns.includes(i)) {
      if (sortInfo && sortInfo.index !== i) {
        data.sort((a, b) => {
          const s = getValues(i, a);
          const d = getValues(i, b);
          if (i === 1) return sortInfo.asc ? d - s : s - d;
          else return sortInfo.asc ? d.localeCompare(s) : s.localeCompare(d);
        });
        setSortInfo({ index: i, asc: !sortInfo.asc });
      } else {
        data.sort((a, b) => {
          if (i === 1) return getValues(i, a) - getValues(i, b);
          else return getValues(i, a).localeCompare(getValues(i, b));
        });
        setSortInfo({ index: i, asc: true });
      }
      setData([...data]);
    } else return;
  };

  return (
    <>
      <div className="extract-main-container">
        <NavHeader show={1} />
        <ShowJobDescription
          toogleJob={toogleJob}
          setToggleJob={() => setToggleJob(!toogleJob)}
        />
        <Paper className="extract-header-container" elevation={0}>
          <TextField
            size="small"
            label="Date"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <div>
            <input type="file" accept=".csv" onChange={handleFileUpload} />
            <IconButton onClick={uploadFile} disabled={!csvData}>
              <FileUploadIcon />
            </IconButton>
            <IconButton onClick={addRow}>
              <AddIcon />
            </IconButton>
          </div>
        </Paper>
        {data?.length > 0 && (
          <TableContainer
            component={Paper}
            className="extract-table-container"
            style={{
              maxHeight: `calc(100% - ${!toogleJob ? "182px" : "232px"})`,
            }}
            elevation={3}
            ref={tableContainer}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {columns.map((c, i) => (
                    <TableCell
                      align="center"
                      key={c}
                      className={i < 5 && "sticky-column elevate-tr"}
                      style={columnStyle(i)}
                      onClick={() => sortColumns(i)}
                    >
                      <div>
                        <strong>{c}</strong>
                        {sortableColumns.includes(i) && (
                          <SwapVertIcon fontSize="14px" />
                        )}
                      </div>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell
                      align="center"
                      className="sticky-column"
                      style={columnStyles.Col1}
                    >
                      {index + 1}
                    </TableCell>
                    <TableCell
                      align="center"
                      className="sticky-column"
                      style={columnStyles.Col2}
                    >
                      {getStandardDate(row.createdAt)}
                    </TableCell>
                    {row.candidate.map(
                      (value, num) =>
                        !excludedColumnIndex.includes(num) && (
                          <TableCell
                            align="center"
                            key={num}
                            className={num < 3 && "sticky-column"}
                            style={columnStyle(num + 2)}
                          >
                            <div
                              contentEditable
                              onBlur={(e) =>
                                focusOut(e.target.innerText, num, row._id)
                              }
                            >
                              {value}
                            </div>
                          </TableCell>
                        )
                    )}
                    <TableCell align="center">
                      <RowDropDown
                        rowVal={data}
                        rowIndex={index}
                        phase="agent"
                        setRows={setData}
                      />
                    </TableCell>
                    <TableCell align="center">
                      {row.status.stage > 2 ? row.status.option : "Shortlisting"}
                    </TableCell>
                    <TableCell align="center">
                      <InfoIcon
                        onClick={() => setOpen(index)}
                        style={{ cursor: "pointer" }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
      {open > -1 && (
        <ProfileDetails
          open={open}
          handleClose={() => setOpen(-1)}
          rows={data}
          setRows={setData}
        />
      )}
    </>
  );
}
