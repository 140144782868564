import React, { useState } from "react";
import "./register.scss";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { postCall } from "../../services/API";
import { userRoles } from "../../helper/constants";
import DropDown from "../../components/dropdown/Select";

export default function Register() {
  const roles = Object.values(userRoles).slice(2,3);
  const navigate = useNavigate();

  const [errorMessages, setErrorMessages] = useState({
    show: false,
    flag: false,
  });
  const [email, setEmail] = useState("abc@gmail.com");
  const [name, setName] = useState("");
  const [role, setRole] = useState("");

  const handleSubmit = () => {
    setErrorMessages({ show: true, flag: true });

    const request = {
      role,
      name,
      email,
      password: "1234",
      employee: [],
      otherEmployee: []
    };

    postCall("users", request)
      .then((res) => {
        console.log(res);
        navigate("/login");
      })
      .catch((err) => {
        setErrorMessages({ show: true, flag: false });
        console.log("vefv ", err);
      });
  };

  const renderErrorMessage = () =>
    errorMessages.show &&
    (!errorMessages.flag ? (
      <div className="first-error">Internal server error!!!</div>
    ) : (
      <div className="first-success">Creating User ...</div>
    ));
  return (
    <div className="signup-main-container">
      <Paper className="signup-container">
        <div className="title">Welcome User!!!</div>
        <TextField
          size="small"
          label="Name"
          type="text"
          fullWidth
          onChange={(e) => setName(e.target.value)}
          value={name}
        />
        <TextField
          size="small"
          label="Email"
          type="email"
          fullWidth
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />
        <TextField size="small" label="Password" type="password" fullWidth />
        <DropDown
          label="Role"
          options={roles}
          value={role}
          setValue={(e) => setRole(e.target.value)}
        />
        {renderErrorMessage()}
        <Button
          variant="outlined"
          className="register-button"
          onClick={handleSubmit}
        >
          Register
        </Button>
        <a href="/login">Go to Login</a>
      </Paper>
    </div>
  );
}
