import { createBrowserRouter } from "react-router-dom";
import Login from "../auth/login/login";
import Register from "../auth/register/register";
import Landing from "../landing/main/body/landing";
import { admin, agent, operation, owner } from "./paths";
import {
  adminMapping,
  agentMapping,
  operationMapping,
  ownerMapping,
} from "../landing/mapping";

export const router = createBrowserRouter([
  {
    path: "login",
    element: <Login />,
  },
  {
    path: "",
    element: <Login />,
  },
  {
    path: "register",
    element: <Register />,
  },
  {
    path: admin,
    element: <Landing />,
    children: adminMapping,
  },
  {
    path: agent,
    element: <Landing />,
    children: agentMapping,
  },
  {
    path: operation,
    element: <Landing />,
    children: operationMapping,
  },
  {
    path: owner,
    element: <Landing />,
    children: ownerMapping,
  },
]);
