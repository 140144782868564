import { useState, useEffect } from "react";
import { getCall, patchCall, postCall } from "../../../services/API";
import DropDown from "../../../components/dropdown/Select";
import InField from "../../../components/InputField";
import { clientTypes, statusOrg } from "../../../helper/constants";
import { Button } from "@mui/material";

export default function DefineCompany({ eIndex, toggle, rows }) {
  const sessionDetails = JSON.parse(localStorage.getItem("nt-session"));

  const creatUpd = eIndex === -1 ? "Create" : "Update";
  const clientTypeList = Object.values(clientTypes);
  const statusOrgList = Object.values(statusOrg);

  const [orgDetails, setOrgDetails] = useState(
    eIndex === -1
      ? {
          orgName: "",
          status: "",
          clientOwner: "",
          clientType: "",
          taggedOrg: "",
        }
      : rows[eIndex]
  );

  const updateVal = (event) => {
    const field = event.target.name;
    const obj = {};
    obj[field] = event.target.value;
    setOrgDetails((val) => {
      return { ...val, ...obj };
    });
  };

  const createOrg = () => {
    const request = {
      orgName: orgDetails.orgName,
      clientType: orgDetails.clientType,
      clientOwner: orgDetails.clientOwner,
      taggedOrg: orgDetails.taggedOrg,
      status: orgDetails.status,
      user: sessionDetails._id,
    };

    if (eIndex === -1) {
      postCall("company/" + sessionDetails._id, request).then((res) => {
        console.log("company", res);
        rows.push(res.data);
        toggle();
      });
    } else {
      patchCall("company/" + rows[eIndex]._id, request).then((res) => {
        console.log("company", res);
        rows.splice(eIndex, 1, res.data);
        toggle();
      });
    }
  };

  return (
    <div className="create-org-container" style={{ gap: "2rem" }}>
      <div className="header" style={{ marginTop: "2rem" }}>
        {creatUpd} Organization
      </div>
      <InField
        label="Organization Name"
        value={orgDetails.orgName}
        onChange={updateVal}
        name="orgName"
      />
      <InField
        label="Client Owner"
        value={orgDetails.clientOwner}
        onChange={updateVal}
        name="clientOwner"
      />
      <DropDown
        label="Type of Client"
        options={clientTypeList}
        value={orgDetails.clientType}
        setValue={updateVal}
        name="clientType"
      />
      <InField
        label="Tagged Organization"
        value={orgDetails.taggedOrg}
        onChange={updateVal}
        name="taggedOrg"
      />
      <DropDown
        label="Status"
        options={statusOrgList}
        value={orgDetails.status}
        setValue={updateVal}
        name="status"
      />
      <Button variant="contained" onClick={createOrg}>
        {creatUpd} Org
      </Button>
    </div>
  );
}
