import { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import "./organisation.scss";
import DataTable from "../../../components/data-table/table";
import {
  orgColumnNames,
  orgRowFields,
} from "../../../components/data-table/tableData";
import RightDrawer from "../../../components/create-drawer/rightDrawer";
import { getCall } from "../../../services/API";
import Search from "../../../components/Search";
import ContainedButton from "../../../components/ContainedButton";

export default function Organisation() {
  const sessionDetails = JSON.parse(localStorage.getItem("nt-session"));
  const [data, setData] = useState([]);
  const [state, setState] = useState(false);

  const getCompany = () => {
    getCall("company/admin/" + sessionDetails._id).then((res) => {
      console.log("company ", res.data);
      setData(res.data);
    });
  };
  const toggleDrawer = (param) => {
    if (param === "success") {
      getCompany();
    }
    setState(!state);
  };
  useEffect(() => {
    getCompany();
  }, []);

  return (
    <div className="org-main-container">
      <Paper className="org-header-section" elevation={0}>
        <Search
          label="Search for Organisation"
          list={data}
          setList={setData}
          reset={state}
          field={orgRowFields[0]}
        />
        <ContainedButton onClick={toggleDrawer}>Add Job Role</ContainedButton>
      </Paper>
      <DataTable columns={orgColumnNames} rows={data} rowKeys={orgRowFields} />
      {state && (
        <RightDrawer
          open={state}
          toggle={toggleDrawer}
          rows={data}
          eIndex={-1}
        />
      )}
    </div>
  );
}
