import { useState, useEffect } from "react";
import { Paper } from "@mui/material";
import "./company.scss";
import DataTable from "../../../components/data-table/table";
import RightDrawer from "../../../components/create-drawer/rightDrawer";
import {
  companyColumnNames,
  companyRowFields,
} from "../../../components/data-table/tableData";
import { getCall } from "../../../services/API";
import Search from "../../../components/Search";
import ContainedButton from "../../../components/ContainedButton";

export default function Company() {
  const sessionDetails = JSON.parse(localStorage.getItem("nt-session"));
  const [state, setState] = useState(false);
  const [editIndex, setEditIndex] = useState(-1);
  const [data, setData] = useState([]);

  const toggleDrawer = () => {
    setState(!state);
  };

  useEffect(() => {
    getCall("company/user/" + sessionDetails._id).then((res) => {
      console.log("company ", res.data);
      setData(res.data);
    });
  }, []);

  return (
    <div className="company-main-container">
      <Paper className="company-header-section" elevation={0}>
        <Search
          label="Search for Company"
          list={data}
          setList={setData}
          reset={state}
          field={companyRowFields[0]}
        />
        <ContainedButton onClick={toggleDrawer}>Add Company</ContainedButton>
      </Paper>
      <DataTable
        columns={companyColumnNames}
        rows={data}
        addToggle={toggleDrawer}
        rowKeys={companyRowFields}
        eIndexSet={setEditIndex}
      />
      {/* "state" flag is required to reset form */}
      {state && (
        <RightDrawer
          open={state}
          toggle={toggleDrawer}
          rows={data}
          eIndex={editIndex}
        />
      )}
    </div>
  );
}
