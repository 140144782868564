import { useState, useEffect } from "react";
import { MenuItem, Select } from "@mui/material";

export default function LineChartMeta({
  range,
  handleRangeSet,
  nums,
  handleNumSet,
}) {

  return (
    <div className="chart-options-container">
      <Select value={nums} onChange={handleNumSet} size="small">
        <MenuItem value="5">5</MenuItem>
        <MenuItem value="7">7</MenuItem>
        <MenuItem value="10">10</MenuItem>
        <MenuItem value="15">15</MenuItem>
      </Select>
      <div></div>
      <div className="chart-title">Growth</div>
      <Select value={range} onChange={handleRangeSet} size="small">
        <MenuItem value="0">WTD</MenuItem>
        <MenuItem value="1">MTD</MenuItem>
        <MenuItem value="2">Day</MenuItem>
      </Select>
    </div>
  );
}
